// react
import React from "react";
import {Table} from "react-bootstrap";
import {AiOutlineInfoCircle} from "react-icons/ai";

// local
import {PollSummary} from "../../types";
import {PopupMessage} from "../../components";

/**
 * Course Categories
 */

interface CompanyPolls {
	id: string,
	company: string,
	title: string,
	polls: PollSummary[]
}

interface SummaryProps {	startYear: number,
	startMonth: number,
	endYear: number,
	endMonth: number,
	summary: PollSummary[],
	onSelectCompany: (companyId: string) => void,
	companyId: string
}
interface SummaryState {
	showInfoMessage: boolean,
	infoTitle: string,
	infoBody: any,
}

export class SurveySummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			showInfoMessage: false,
			infoTitle: "Navigating Survey Completion",
			infoBody: <div>Select a location by clicking the list item. Once selected, the 'Response Details' list will show only that location's results. <br/>Revert back to the global view by re-clicking the selected location.</div>
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		const {summary, onSelectCompany, companyId, startMonth, endMonth, endYear} = this.props;
		const {showInfoMessage, infoTitle, infoBody} = this.state;
		const year = endYear - 2000;

		// Compute 3 months of stats.
		let companies: CompanyPolls[] = [];
		for (let i = 0; i < summary.length; i++) {
			const item = summary[i];
			let company = companies.find(c => c.company === item.company);
			if (!company) {
				company = {id: item.id, company: item.company, title: item.title, polls: Array(3)};
				companies.push(company);
			}
			company.polls[item.month-startMonth] = item;
		}

		// Determine the components.
		let header = [];
		header.push(<th className="index">#</th>);
		header.push(<th className="description">Location</th>);
		for (let month = startMonth; month <= endMonth; month++) {
			header.push(<th className="value">{month + '/' + year}</th>);
		}
		header.push(<th className="value">%</th>);

		let body = [];
		for (let i = 0; i < companies.length; i++) {
			// setup
			const item = companies[i];
			const key = 'summary-' + i;
			const selected = (item.company === companyId) ? 'selected' : 'selectable';

			let values = [];
			for (let month = startMonth; month <= endMonth; month++) {
				const poll = item.polls[month-startMonth];
				const completed = (poll && poll.completed) ? poll.completed : 0;
				const total = completed ? Math.ceil(completed * 100 / poll.total) + '%' : '0%';
				values.push(<td className="value">{completed}</td>)
				if (month === endMonth) {
					values.push(<td className="value">{total}</td>)
				}
			}
			body.push(
				<tr
					key={key}
					className={selected}
					onClick={() => onSelectCompany(item.company)}
				>
					<td className="index">{i}</td>
					<td className="description">{item.title}</td>
					{values}
				</tr>
			);
		}

		let footer = [];
		footer.push(<td colSpan={2}></td>);
		for (let month = startMonth; month <= endMonth; month++) {
			let completed = 0;
			let total = 0;
			for (let i = 0; i < companies.length; i++) {
				const poll = companies[i].polls[month-startMonth];
				if (poll && poll.completed) {
					completed += poll.completed;
					total += poll.total;
				}
			}

			footer.push(<td className="value">{completed.toLocaleString()}</td>);
			if (month === endMonth) {
				footer.push(<td className="value">{total ? Math.ceil(completed * 100 / total) + '%' : '0%'}</td>);
			}
		}

		// render
		return (
			<div id="SurveySummary" key="SurveySummary">
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">Survey Participation</h4>

				<Table id="SurveySummary-Table" striped bordered responsive>
					<thead><tr key='survey-header'>{header}</tr></thead>
					<tbody>{body}</tbody>
					<tfoot><tr key='survey-footer'>{footer}</tr></tfoot>
				</Table>

				<PopupMessage
					show={!!showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		)
	}

}
