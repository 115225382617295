// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {Client, CourseInfo, firstLastToName, formatPhoneNumber, timestampToStrDate, User} from "../../types";
import {NavigateFunction} from "react-router-dom";

/**
 * Course Categories
 */

interface SummaryProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	courses: CourseInfo[],
	courseId: number,
	onSelectItem: (studentId: number, orderId: number, courseId: number) => void
}
interface SummaryState {
	companies: number
}

export class StudentsSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			companies: this.getCompanyCount(props.courses)
		}

		// Register the callbacks.
		this.onViewItem = this.onViewItem.bind(this);
	}

	getCompanyCount(courses: CourseInfo[]) {
		let count = 0;
		let name = "";
		for (let i = 0; i < courses.length; i++) {
			if (courses[i].company_name !== name) {
				name = courses[i].company_name;
				count++;
			}
		}
		return count;
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// setup
		const {courses, courseId, onSelectItem} = this.props;
		const {companies} = this.state;

		// Construct the list.
		const header = [
			<tr key='course-company-header'>
				<th className="value">ID</th>
				<th className="student">Student</th>
				<th className="description">Course</th>
				<th className="date">Enrolled</th>
				<th className="date">Started</th>
				<th className="date">Progress</th>
				<th className="date">Finished</th>
				<th className="value">Score</th>
			</tr>
		];

		let tEnrolled = 0;
		let tStarted = 0;
		let tCompleted = 0;
		let company: string = "";
		const body = [];
		for (let i = 0; i < courses.length; i++) {
			// company
			const item = courses[i];
			if (companies > 1 && item.company_name !== company) {
				company = item.company_name;
				body.push(<tr key={'course-company'+item.id}>
					<td className="location" colSpan={9}>{company}</td>
				</tr>);
			}

			// record
			let enrolled;
			if (item.enrolled) {
				enrolled = timestampToStrDate(item.enrolled, false);
				tEnrolled++;
			} else {
				enrolled = "";
			}
			let started;
			if (item.started) {
				started = timestampToStrDate(item.started, false);
				tStarted++;
			} else {
				started = "";
			}
			let completed;
			if (item.completed) {
				completed = timestampToStrDate(item.completed, false);
				tCompleted++;
			} else {
				completed = "";
			}

			const selected = (item.id === courseId) ? 'selectable selected' : 'selectable';
			const name = firstLastToName(item.user_last_name, item.user_first_name);
			const phone = formatPhoneNumber(item.phone);
			const score = (item.completed && item.total) ? item.total + '%' : '';
			const percent = item.mod_progress + '%';

			body.push(
				<tr
					key={'course-'+item.id}
					className={selected}
					onClick={() => onSelectItem(item.user_id, 0, item.id)}
				>
					<td
						className='link'
						onClick={() => this.onViewItem(item.user_id, 0, item.id)}
					>
						{item.id}
					</td>
					<td className='student'>
						<div>{name}</div>
						<div>{item.email}</div>
						<div>{phone}</div>
					</td>
					<td className='description'>{item.course_title}</td>
					<td className='date'>{enrolled}</td>
					<td className='date'>{started}</td>
					<td className='value'>{percent}</td>
					<td className='date'>{completed}</td>
					<td className='value'>{score}</td>
				</tr>
			);
		}

		const footer =
			<tr key='course-company-footer'>
				<td/><td/><td/>
				<td className='value'>{tEnrolled.toLocaleString()}</td>
				<td className='value'>{tStarted.toLocaleString()}</td>
				<td/>
				<td className='value'>{tCompleted.toLocaleString()}</td>
				<td/>
			</tr>;

		// render
		return (
			<div id="StudentsSummary">
				<Table id="StudentsSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		)
	}

}
