// react
import React from "react";
import {AiOutlineBarChart} from "react-icons/ai";

// local
import {Client, SalesInfo, Filters, User} from "../../types";
import {SalesSummary} from "./SalesSummary"
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router-dom";

/**
 * LocationSection
 */

interface SectionProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	filters: Filters,
	startDate: string,
	endDate: string,
	onToggleSearchForm: (formName: string | null) => void
}
interface SectionState
{
	loaded: boolean,
	fSales: SalesInfo[],
	salesId: number,
	clientName: string
	filter: string
}

export class SalesSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		this.state = {
			loaded: false,
			clientName: props.client.name,
			fSales: [],
			salesId: 0,
			filter: ''
		};

		// Register the callbacks.
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onSelectItem = this.onSelectItem.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, filters, startDate, endDate} = this.props;
		const {clientName} = this.state;

		const data = await client.fetchSalesAnalytics(clientName, startDate, endDate);
		this.setState({loaded: true,
			fSales: this.filterSales(data), filter: filters.filter
		});
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client, filters, startDate, endDate} = this.props;
		const {clientName} = this.state;

		if (pProps.client.name !== clientName || pProps.startDate !== startDate || pProps.endDate !== endDate) {
			const data = await client.fetchSalesAnalytics(client.name, startDate, endDate);
			this.setState({loaded: true, clientName: client.name, filter: filters.filter,
				fSales: this.filterSales(data)
			});
		} else if (filters.filter !== pState.filter) {
			const data = await client.fetchSalesAnalytics(client.name, startDate, endDate);
			this.setState({fSales: this.filterSales(data), filter: filters.filter});
		}
	}

	filterSales(sales: SalesInfo[]) {
		// setup
		const {filters} = this.props;

		// Filter according to the fields.
		if (filters.last_name) {
			const filter = filters.last_name.toLowerCase();
			sales = sales.filter(item => item.user_last_name.toLowerCase().includes(filter));
		}
		if (filters.first_name) {
			const filter = filters.first_name.toLowerCase();
			sales = sales.filter(item => item.user_first_name.toLowerCase().includes(filter));
		}
		if (filters.email) {
			const filter = filters.email.toLowerCase();
			sales = sales.filter(item => item.email.toLowerCase().includes(filter));
		}
		if (filters.phone) {
			const filter = filters.phone;
			sales = sales.filter(item => item.phone.includes(filter));
		}
		if (filters.course_title) {
			const filter = filters.course_title.toLowerCase();
			sales = sales.filter(item => item.order_item_name.toLowerCase().includes(filter));
		}

		// Return the results.
		sales = sales.sort((a, b) => {
			if (a.company_id === b.company_id) {
				return a.id > b.id ? 1 : -1;
			}
			return a.company_id > b.company_id ? 1 : -1;
		});
		return sales;
	}

	onGenerateReport = async () => {
		// setup
		const {client, startDate, endDate} = this.props;
		const csvData = await client.generateReport(client.name, startDate, endDate);

		const type = 'text/csv';
		const blob = new Blob([csvData], { type: type });
		const dataURI = `data: ${type};charset=utf-8,` + csvData;

		const URL = window.URL || window.webkitURL;
		const filename = `${client.name}-${startDate}-${endDate}.csv`;
		const href = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

		let link = document.createElement('a');
		link.download = filename;
		link.href = href;
		link.target = '_blank';
		link.click();
	}

	onSelectItem(salesId: number) {
		this.setState({salesId});
	}

	render() {
		// setup
		const {user, client, navigate, onToggleSearchForm} = this.props;
		const {loaded, fSales, salesId} = this.state;
		const search = 'Sales Search';

		// render
		if (!loaded) {
			return<></>
		}
		return (
			<div>
				<div className='section'>
					<div className='section-header'>
						<AiOutlineBarChart className="section-icon" size={28}/>
						Sales Summary
						<Button className='navigate-search' variant="forgot" size="sm" onClick={() => onToggleSearchForm(search)}>
							{search}
						</Button>
						<Button className='navigate-report' variant="forgot" size="sm" onClick={this.onGenerateReport}>
							Generate Report
						</Button>
					</div>

					<div className="row">
						<SalesSummary
							user={user}
							client={client}
							navigate={navigate}
							sales={fSales}
							salesId={salesId}
							onSelectItem={this.onSelectItem}
						/>
					</div>
					<hr/>
				</div>
			</div>
		)
	}
}
