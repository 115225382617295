// react
import React from "react";
import {Button, Form} from "react-bootstrap";

// local
import {User} from "../types";

/**
 * LoginForm
 */

interface FormProps {
	user: User,
	submit: (action: string) => void
}
interface FormState {
}

export class ForgotPasswordForm extends React.Component<FormProps, FormState> {

	onFormCancel = async (event: any) => {
		this.props.submit('cancel');
	}

	onFormSubmit = async (event: any) => {
		// setup
		const {user} = this.props;
		event.preventDefault();
		const fields = event.currentTarget.elements;

		// Update the user information.
		const email = fields.email.value;
		await user.forgotPassword(email);

		// Return to the login form.
		this.props.submit('close');
	}

	render() {
		return (
			<div className="form-body">
				<div className="form">
					<Form id="ForgotPasswordForm" className="mt-3" onSubmit={this.onFormSubmit}>

						{ /* email */ }
						<Form.Group className="mb-3 form-field" controlId="email">
							<Form.Control type="email" placeholder="Enter email" />
						</Form.Group>
						<Button variant="primary" className="btn-wide mt-3" type="submit">Send Reset Email</Button>

					</Form>
				</div>

				<Button variant="forgot" className="btn-wide" size="sm" onClick={this.onFormCancel}>
					Go Back to Login
				</Button>

			</div>);
	}
}
