// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {ActivitySummary} from "../../types";

/**
 * Course Views
 */

interface ViewsProps {
	activities: ActivitySummary[],
	activityId: string
}

export const CoursesViews = (props: ViewsProps) => {

	// setup
	let total = 0;
	const {activities, activityId} = props;

	function createViewingGroups() {
		// setup
		let category = null;
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			const key = `${item.activity_type}-${i}`;
			const sPercent = item.started_percent.toString() + '%';
			const cPercent = item.completed_percent.toString() + '%';
			total += item.total;

			// render
			if (item.activity_category !== category) {
				category = item.activity_category;
				body.push(<tr key={'course-views-category'+total}>
					<td className="location" colSpan={6} >{category}</td>
				</tr>);
			}
			body.push(<tr key={key}>
				<td className="description">{item.activity_title}</td>
				<td className="value">{item.total}</td>
				<td className="value">{item.started}</td>
				<td className="percent">{sPercent}</td>
				<td className="value">{item.completed}</td>
				<td className="percent">{cPercent}</td>
			</tr>);
		}
		return {
			title: 'Viewing Details',
			col: 'Course',
			body
		};
	}

	function createViewingList() {
		// setup
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			if (item.activity_title !== activityId) {
				continue;
			}
			const key = `${item.activity_type}-${i}`;
			const sPercent = item.started_percent.toString() + '%';
			const cPercent = item.completed_percent.toString() + '%';

			// render
			body.push(<tr id={key}>
				<td className="description">{item.activity_category}</td>
				<td className="value">{item.total}</td>
				<td className="value">{item.started}</td>
				<td className="percent">{sPercent}</td>
				<td className="value">{item.completed}</td>
				<td className="percent">{cPercent}</td>
			</tr>);
		}
		return {
			title: activityId,
			col: 'Company',
			body
		};
	}

	// Construct the list.
	const details = activityId ? createViewingList() : createViewingGroups();
	const header = [
		<tr key='courses-views-header'>
			<th className="description">{details.col}</th>
			<th className="value">enrolled</th>
			<th className="value">started</th>
			<th className="percent">%</th>
			<th className="value">finished</th>
			<th className="percent">%</th>
		</tr>
	];

	// render
	return (
		<div id="CourseViews">
			<h4 className="title">{details.title}</h4>
			<Table id="CourseViews-Table" striped bordered responsive>
				<thead>{header}</thead>
				<tbody>{details.body}</tbody>
			</Table>
		</div>
	);

}

