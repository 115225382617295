// react
import React from "react";
import {RiCouponLine} from "react-icons/ri";

// local
import {Client, CouponInfo, CouponUseInfo, User} from "../../types";
import {CouponsSummary} from "./CouponsSummary";
import {Coupons} from "./Coupons";
import {NavigateFunction} from "react-router-dom";

/**
 * CourseSection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction,
	startDate: string,
	endDate: string,
}
interface SectionState
{
	loaded: boolean,
	coupons: CouponInfo[],
	couponId: number,
	use: CouponUseInfo[],
	clientName: string
}

export class CouponsSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		this.state = {
			loaded: false,
			coupons: [],
			use: [],
			couponId: 0,
			clientName: props.client.name,
		};

		// Register the callbacks.
		this.onSelectItem = this.onSelectItem.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, startDate, endDate} = this.props;
		const {clientName} = this.state;

		const data = await client.fetchCouponAnalytics(clientName, startDate, endDate);
		this.setState({loaded: true, coupons: data});
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client, startDate, endDate} = this.props;
		const {clientName} = this.state;

		if (pProps.client.name !== clientName || pProps.startDate !== startDate || pProps.endDate !== endDate) {
			const data = await client.fetchCouponAnalytics(clientName, startDate, endDate);
			this.setState({loaded: true, clientName: client.name, coupons: data});
		}
	}

	onSelectItem(couponId: number) {
		const item = this.state.coupons.find(item => item.id === couponId)
		this.setState({couponId, use: item ? item.use : []});
	}

	render() {
		// setup
		const {client, navigate, user} = this.props;
		const {coupons, couponId, use} = this.state;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<RiCouponLine className="section-icon" size={28}/>
					Affiliate Codes
				</div>

				<div className="row">
					<div className="column">
						<CouponsSummary
							client={client}
							coupons={coupons}
							couponId={couponId}
							onSelectItem={this.onSelectItem}
							/>
					</div>
					<div className="column">
						<Coupons
							user={user}
							navigate={navigate}
							use={use}
						/>
					</div>
				</div>

				<hr/>
			</div>
		)
	}
}

