// react
import React from "react";
import {Dropdown, Form} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import {NavigateFunction} from "react-router-dom";
import moment from 'moment';

// local
import {
	clients,
	Client,
	Department,
	PageProps,
	withNavigation,
	User,
	System,
	Filters,
	PageNav,
	LOC_SDATE_FORMAT,
	UTC_DATE_FORMAT
} from "../types";
import {
	Footer,
	Loading,
	Header,
	AppSidebar,
	AppSidebarIcon,
	AppMenu,
	AppSettings
} from "../components";
import {
	ClientInfoForm,
	ClientDesignForm,
	SearchForm
} from "../forms";
import {
	SalesSection,
	CategorySection,
	CouponsSection,
	CourseSection,
	EngagementSection,
	InsightsSection,
	LocationSection,
	SurveySection,
	StudentsSection
} from "../sections";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	client: Client,
	isManager: boolean,
	departments: Department[],
	departmentId: string
	startDate: string,
	endDate: string,
	onChangeTimeframe: (startDate: string, endDate: string) => void
}

const Subheader = (props: SubheaderProps) => {

	// values
	let startDate = props.startDate;
	let endDate = props.endDate;

	// setup
	const {client, isManager, departments, departmentId, onChangeTimeframe} = props;
	const editDepartment = client.url.includes('blunovus');
	const editTimeframes = client.url.includes('aaadrivingcourse') || client.url.includes('washingtonagd');

	const onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = props;

		// Update the settings.
		await client.setClient(clientId);
	}

	const onChangeDepartment = async (eventKey: any) => {
		// Update the settings.
		await client.setDepartment(eventKey);
	}

	const onSubmitTimeframe = async (eventKey: any) => {
		// Update the settings.
		if (eventKey !== 'timeframe-custom') {
			const dates = eventKey.split(' ');
			startDate = dates[0];
			endDate = dates[1];
		} else {
			const startField = document.getElementById('start_date');
			// @ts-ignore
			startDate = startField.value;
			const endField = document.getElementById('end_date');
			// @ts-ignore
			endDate = endField.value;
		}
		onChangeTimeframe(startDate, endDate);
	}

	function onChangeValue(id: string, value: string) {
		if (id === 'start_date') {
			startDate = value;
		} else {
			endDate = value;
		}
	}

	const renderClients = (clientId: string | null) => {
		// setup
		let eClients = clients.mapParentItems();

		// render
		return <Dropdown className="clients" onSelect={onChangeClient}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{clientId ? clientId : "Choose a client"}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{eClients}
			</Dropdown.Menu>
		</Dropdown>
	}

	const renderDepartments = (departments: Department[], departmentId: string | null) => {
		// setup
		let eDepartments = departments.map(item => <Dropdown.Item id={item.id} key={item.id} eventKey={item.name}>
				{item.name}
			</Dropdown.Item>);
		eDepartments.unshift(<Dropdown.Item id={'all'} key={'all'} eventKey={'All Departments'}>
			All Departments
		</Dropdown.Item>)

		// render
		return <Dropdown className="departments" onSelect={onChangeDepartment}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{departmentId ? departmentId : "Choose a department"}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{eDepartments}
			</Dropdown.Menu>
		</Dropdown>
	}

	const renderTimeframes = () => {
		// setup
		const now = moment();
		const strNow = now.format(LOC_SDATE_FORMAT);

		// month
		const startThisMonth = moment().startOf('month');
		const strStartThisMonth = startThisMonth.format(LOC_SDATE_FORMAT);
		const thisMonthKey = startThisMonth.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		const startLastMonth = moment().subtract(1, 'month').startOf('month');
		const endLastMonth = moment(startLastMonth).endOf('month');
		const strStartLastMonth = startLastMonth.format(LOC_SDATE_FORMAT);
		const strEndLastMonth = endLastMonth.format(LOC_SDATE_FORMAT);
		const lastMonthKey = startLastMonth.format(UTC_DATE_FORMAT)+' '+endLastMonth.format(UTC_DATE_FORMAT);

		// quarter
		let year = now.year();
		let month = now.month();

		let quarterYear = year;
		let quarterMonth = (month >= 3) ? (month - 3) % 3 : 0;
		const startThisQuarter = moment().set('year', quarterYear).set('month', quarterMonth).startOf('month');
		const strStartThisQuarter = startThisQuarter.format(LOC_SDATE_FORMAT);
		const thisQuarterKey = startThisQuarter.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		if (quarterMonth >= 3) {
			quarterMonth -= 3;
		} else {
			quarterMonth = 9;
			quarterYear--;
		}
		const startLastQuarter = moment().set('year', quarterYear).set('month', quarterMonth).startOf('month');
		const endLastQuarter = moment().set('year', quarterYear).set('month', quarterMonth + 2).endOf('month');
		const strStartLastQuarter = startLastQuarter.format('MM/DD/YY');
		const strEndLastQuarter = endLastQuarter.format('MM/DD/YY');
		const lastQuarterKey = startLastQuarter.format(UTC_DATE_FORMAT)+' '+endLastQuarter.format(UTC_DATE_FORMAT);

		// year
		const startThisYear = moment().set('year', year).set('month', 0).startOf('month');
		const strStartThisYear = startThisYear.format(LOC_SDATE_FORMAT);
		const thisYearKey = startThisYear.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		const startLastYear = moment().subtract(1, 'year');
		const strStartLastYear = startLastYear.format('MM/DD/YY');
		const strEndLastYear = now.format('MM/DD/YY');
		const lastYearKey = startLastYear.format(UTC_DATE_FORMAT)+' '+now.format(UTC_DATE_FORMAT);

		const start = moment(startDate).format('MM/DD/YY');
		const end = moment(endDate).format('MM/DD/YY');
		const title = 'Timeframe: '+start+'-' + end;

		// render
		return (
			<Dropdown className="timeframes" onSelect={onSubmitTimeframe}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{title}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{/* current */}
					<Dropdown.Item key='timeframe-this-month' eventKey={thisMonthKey}>
						<div>This Month <span>(./bn_{strStartThisMonth}&ndash;{strNow})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-this-quarter' eventKey={thisQuarterKey}>
						<div>This Quarter <span>({strStartThisQuarter}&ndash;{strNow})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-this-year' eventKey={thisYearKey}>
						<div>This Year <span>({strStartThisYear}&ndash;{strNow})</span></div>
					</Dropdown.Item>

					{/* last */}
					<Dropdown.Item key='timeframe-last-month' eventKey={lastMonthKey} className="dropdown-section">
						<div>Last Month <span >({strStartLastMonth}&ndash;{strEndLastMonth})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-last-quarter' eventKey={lastQuarterKey}>
						<div>Last Quarter <span>({strStartLastQuarter}&ndash;{strEndLastQuarter})</span></div>
					</Dropdown.Item>
					<Dropdown.Item key='timeframe-last-year' eventKey={lastYearKey}>
						<div>Last Year <span>({strStartLastYear}&ndash;{strEndLastYear})</span></div>
					</Dropdown.Item>

					{/* custom */}
					<Dropdown.Item key='timeframe-custom' eventKey='timeframe-custom' className="dropdown-section">
						<div className='help-title'>Custom &hellip;<span>SELECT</span></div>
						<div className='help-text'>choose dates from calendars below<br/>and press 'SELECT'</div>
					</Dropdown.Item>
					<Form.Group controlId="start_date" className="field-flex">
						<Form.Label className="field-flex-label">Start:</Form.Label>
						<Form.Control
							className="field-flex-value" type="date"
							onChange={(e) => onChangeValue(e.target.id, e.target.value)}
							defaultValue={startDate}
						/>
					</Form.Group>
					<Form.Group controlId="end_date" className="field-flex">
						<Form.Label className="field-flex-label">End:</Form.Label>
						<Form.Control
							className="field-flex-value" type="date"
							onChange={(e) => onChangeValue(e.target.id, e.target.value)}
							defaultValue={endDate}
						/>
					</Form.Group>
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	// render
	return (
		<div className="subheader">
			{isManager && renderClients((client && client.name) ? client.name : "")}
			{editDepartment && departments.length > 1 && renderDepartments(departments, departmentId)}
			{editTimeframes && renderTimeframes()}
		</div>
	);

}

/**
 * ReportsPage
 */

interface ReportsProps extends PageProps {
}
interface ReportsState {
	loaded: boolean,
	showInfoForm: string | null,
	showDesignForm: string | null,
	showSearchForm: string | null,
	// calendar
	startDate: string,
	endDate: string
}

@inject("system")
@inject("client")
@inject("user")
@inject("filters")
@observer
export class ReportsPageComponent extends React.Component<ReportsProps, ReportsState> {

	constructor(props: ReportsProps) {
		// setup
		super(props);

		// Default date settings.
		const end = moment();
		const start = moment(end).subtract(3, 'months');

		this.state = {
			loaded: false,
			showInfoForm: null,
			showDesignForm: null,
			showSearchForm: null,
			// calendar
			startDate: start.format(UTC_DATE_FORMAT),
			endDate: end.format(UTC_DATE_FORMAT)
		}

		// Register the callbacks.
		this.onChangeClient = this.onChangeClient.bind(this);
		this.onChangeTimeframe = this.onChangeTimeframe.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onNavigateEngagements = this.onNavigateEngagements.bind(this);
		this.onToggleAddClient = this.onToggleAddClient.bind(this);
		this.onToggleEditDesign = this.onToggleEditDesign.bind(this);
		this.onToggleEditInfo = this.onToggleEditInfo.bind(this);
		this.onToggleSearchForm = this.onToggleSearchForm.bind(this);
		this.onToggleSection = this.onToggleSection.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, user, navigate} = this.props;

		// Get the client list and possibly client info.
		if (!client.id) {
			user.logout();
			navigate("/login");
		}

		// Update the state.
		this.setState({loaded: true});
	}

	onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = this.props;

		// Update the settings.
		await client.setClient(clientId);
	}

	onChangeTimeframe = async (startDate: string, endDate: string) => {
		this.setState({startDate, endDate});
	}

	onNavigateEngagements() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/engagements");
	}

	onLogout() {
		// setup
		const {navigate, user, client} = this.props;

		// Logout and go to the login page.
		user.logout();
		client.logout();
		navigate("/login");
	}

	onToggleSection(section: string) {
		// setup
		const {client} = this.props;
		const sort = client.pageNavigation;

		// Show states
		sort.toggle(section);
	}

	onToggleAddClient() {
		this.setState({showDesignForm: null, showInfoForm: this.state.showInfoForm ? null : 'add'});
	}

	onToggleEditDesign() {
		this.setState({showInfoForm: null, showDesignForm: this.state.showDesignForm ? null : 'edit'});
	}

	onToggleEditInfo() {
		this.setState({showDesignForm: null, showInfoForm: this.state.showInfoForm ? null : 'edit'});
	}

	onToggleSearchForm(formName: string | null) {
		this.setState({showSearchForm: formName});
	}

	render() {
		// setup
		const {client, user, navigate, system, filters} = this.props;
		const {loaded, showInfoForm, showDesignForm, showSearchForm} = this.state;
		if (!loaded) {
			return <Loading/>
		}

		// render
		const show = client.pageNavigation;
		return (
			<div className="page">
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>

				<div id="ReportsPage-body" className="body">
					{ this.renderSidebar(user, client, client.pageNavigation) }
					{ this.renderSections(user, client, navigate, system, filters, show) }
				</div>

				<Footer/>

				{ /* popup forms */ }
				{showInfoForm && <ClientInfoForm
					clientId={client.id}
					action={showInfoForm}
					close={this.onToggleEditInfo}
				/>}
				{showDesignForm && <ClientDesignForm
					clientId={client.id}
					close={this.onToggleEditDesign}
				/>}
				{showSearchForm && <SearchForm
					clientId={client.id}
					title={showSearchForm}
					close={() => this.onToggleSearchForm(null)}
				/>}

			</div>
		);
	}

	renderSidebar(user: User, client: Client, show: PageNav) {
		return (
			<AppSidebar>
				{show.settings && user.isCompanyManager() && <AppSettings
					clientName={client.name}
					onAddClient={this.onToggleAddClient}
					onEditClientInfo={this.onToggleEditInfo}
					onEditClientDesign={this.onToggleEditDesign}
				/>}
				<AppSidebarIcon
					section='sales'
					text='Sales'
					state={show.sales}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='engagement'
					text='Engagement'
					state={show.engagement}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='location'
					text='Summary'
					state={show.location}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='students'
					text='Students'
					state={show.students}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='course'
					text='Courses'
					state={show.course}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='coupons'
					text='Codes'
					state={show.coupons}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='category'
					text='Courses'
					state={show.category}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='survey'
					text='Surveys'
					state={show.survey}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='insights'
					text='Insights'
					state={show.insights}
					onSelect={this.onToggleSection}
				/>
			</AppSidebar>
		);
	}

	renderSections(user: User, client: Client, navigate: NavigateFunction, system: System, filters: Filters, show: PageNav) {
		// setup
		const {startDate, endDate} = this.state;
		const isManager = user.isCompanyManager();
		const showDepartments = (client.departments.length > 1);

		// render
		return (
			<div className='section-group'>
				{(isManager || showDepartments) && <Subheader
					client={client}
					isManager={isManager}
					departments={client.departments}
					departmentId={client.departmentId}
					navigate={navigate}
					startDate={startDate}
					endDate={endDate}
					onChangeTimeframe={this.onChangeTimeframe}
				/>}
				<div className="scroll-y">
					{show.engagement === 'selected' &&
						<EngagementSection
							user={user}
							client={client}
							navigate={navigate}
							system={system}
						/>
					}
					{show.sales === 'selected' &&
						<SalesSection
							user={user}
							client={client}
							navigate={navigate}
							filters={filters}
							startDate={startDate}
							endDate={endDate}
							onToggleSearchForm={this.onToggleSearchForm}
						/>
					}
					{show.students === 'selected' &&
						<StudentsSection
							user={user}
							client={client}
							navigate={navigate}
							filters={filters}
							startDate={startDate}
							endDate={endDate}
							onToggleSearchForm={this.onToggleSearchForm}
						/>
					}
					{show.coupons === 'selected' &&
						<CouponsSection
							user={user}
							client={client}
							navigate={navigate}
							startDate={startDate}
							endDate={endDate}
						/>
					}
					{show.course === 'selected' &&
						<CourseSection
							client={client}
						/>
					}
					{show.category === 'selected' &&
						<CategorySection
							user={user}
							client={client}
							navigate={navigate}
						/>
					}
					{show.location === 'selected' &&
						<LocationSection
							client={client}
						/>
					}
					{show.survey === 'selected' &&
						<SurveySection
							client={client}
							navigate={navigate}
						/>
					}
					{show.insights === 'selected' && user.isCompanyManager() &&
						<InsightsSection
							user={user}
							client={client}
						/>
					}
				</div>
			</div>
		);
	}

}

export const ReportsPage = withNavigation(ReportsPageComponent);
