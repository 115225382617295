// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {
	Client,
	firstLastToName,
	SalesInfo,
	timestampToStrDate,
	formatPhoneNumber,
	User,
} from "../../types";
import {NavigateFunction} from "react-router-dom";

/**
 * Course Categories
 */

interface SummaryProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	sales: SalesInfo[],
	salesId: number,
	onSelectItem: (itemId: number) => void
}
interface SummaryState {
	companies: number
}


export class SalesSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			companies: this.getCompanyCount(props.sales)
		}

		// Register the callbacks.
		this.onViewItem = this.onViewItem.bind(this);
	}

	getCompanyCount(sales: SalesInfo[]) {
		let count = 0;
		let name = "";
		for (let i = 0; i < sales.length; i++) {
			if (sales[i].company_name !== name) {
				name = sales[i].company_name;
				count++;
			}
		}
		return count;
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// setup
		const {sales, salesId, onSelectItem} = this.props;
		const {companies} = this.state;
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 0
		});

		// Construct the list.
		const header = [
			<tr key='sales-company-header'>
				<th className="value">ID</th>
				<th className="student">Student</th>
				<th className="description">Course</th>
				<th className="date">Date</th>
				<th className="value">Qty</th>
				<th className="value">Subtotal</th>
				<th className="coupon">Coupon</th>
				<th className="value">Discount</th>
				<th className="value">Total</th>
			</tr>
		];

		let qty = 0;
		let subtotal = 0;
		let discount = 0;
		let coupons = 0;
		let total = 0;
		let company = "";
		const body = [];
		for (let i = 0; i < sales.length; i++) {
			// company
			const item = sales[i];
			if (companies > 1 && item.company_name !== company) {
				company = item.company_name;
				body.push(<tr key={'sales-company'+item.id}>
					<td className="location" colSpan={6}>{company}</td>
				</tr>);
			}

			// record
			qty += item.qty;
			subtotal += item.subtotal;
			discount += item.discount;
			total += item.total;
			if (item.coupon) {
				coupons++;
			}

			const created = timestampToStrDate(item.created, false);
			const selected = (item.id === salesId) ? 'selectable selected' : 'selectable';
			const name = firstLastToName(item.user_last_name, item.user_first_name);
			const phone = formatPhoneNumber(item.phone);
			const coupon = item.coupon ? item.coupon : '';

				body.push(
				<tr
					key={'sale-'+i}
					className={selected}
					onClick={() => onSelectItem(item.id)}
				>
					<td
						className='link'
						onClick={() => this.onViewItem(item.mdl_user_id, item.order_id, 0)}
					>
						{item.id}
					</td>
					<td className='student'>
						<div>{name}</div>
						<div>{item.email}</div>
						<div>{phone}</div>
					</td>
					<td className='description'>{item.order_item_name}</td>
					<td className='date'>{created}</td>
					<td className='value'>{item.qty}</td>
					<td className='value'>{item.subtotal.toLocaleString()}</td>
					<td className='coupon'>{coupon}</td>
					<td className='value'>{item.discount.toLocaleString()}</td>
					<td className='value'>{item.total.toLocaleString()}</td>
				</tr>
			);
		}

		const footer =
			<tr key='sales-company-header'>
				<td/><td/><td/><td/>
				<td className={'value'}>{qty.toLocaleString()}</td>
				<td className={'value'}>{formatter.format(subtotal)}</td>
				<td className={'value'}>{coupons}</td>
				<td className={'value'}>{formatter.format(discount)}</td>
				<td className={'value'}>{formatter.format(total)}</td>
			</tr>;

		// render
		return (
			<div id="UsersSummary">
				<Table id="SalesSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		)
	}

}
