// react
import React from "react";
import {Button, Form} from "react-bootstrap";
import moment from 'moment';

// local
import {clients, CompanyDetails, NameValuePair, UTC_DATE_FORMAT} from "../types";
import {PopupMessage} from "../components";

/**
 * Info
 */

interface DesignProps {
	clientId: number,
	close: () => void,
}

interface DesignState {
	loaded: boolean,
	company: CompanyDetails | null,
	showSaveMessage: string | null
}

export class ClientDesignForm extends React.Component<DesignProps, DesignState> {

	constructor(props: DesignProps) {
		super(props);

		this.state = {
			loaded: false,
			company: null,
			showSaveMessage: null
		}

		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {clientId} = this.props;

		// Get the client details.
		const company = await clients.fetchClientInfo(clientId);
		this.setState({loaded: true, company});
	}

	onDismissPopup() {
		this.setState({showSaveMessage: null});
	}

	onFormClose = async (event: any) => {
		// setup
		this.props.close();
	}

	onFormSubmit = async (event: any) => {
		// setup
		const {company} = this.state;
		if (!company) {
			return;
		}
		const form = event.currentTarget;

		event.preventDefault(); // prevent page from reloading

		// Check for changes.
		let changes: NameValuePair[] = [];
		Object.entries(company).forEach(entry => {
			// setup
			const [key, value] = entry;
			let field = form[key];
			if (field) {
				const type = field.getAttribute('type');
				let srcValue;

				// Fixups.
				if ((key === 'census' || key === 'office_census' || key === 'other_census') && !field.value) {
					srcValue = 0;
				} else if (key === 'parentid') {
					const parent = clients.findByName(field.value);
					srcValue = parent ? parent.id : field.value;
				} else if (type === 'date') {
					const date = moment.utc(field.value, UTC_DATE_FORMAT);
					srcValue = date.valueOf() / 1000;
				} else {
					srcValue = field.value;
				}

				// See if there is a difference.
				if (srcValue !== 'n/a' && srcValue != value) {
					changes.push({name: key, value: srcValue});
				}
			}
		});

		// Update the back-end.
		if (!changes.length) {
			this.setState({showSaveMessage: `There is no information to be updated.`});
			return;
		}

		const success = await clients.updateClientInfo(company.id, changes);
		if (success) {
			this.setState({showSaveMessage: `${company.name}'s information has been updated.`});
		} else if (!success) {
			this.setState({showSaveMessage: `There was a problem updating ${company.name}'s information.`});
		}
	}

	render() {
		// setup
		const {loaded, company, showSaveMessage} = this.state;
		if (!loaded || !company) {
			return <></>
		}

		// render
		return (
			<div id="ClientForm">
				<Form className="form-popup" onSubmit={this.onFormSubmit}>

					{this.renderFormHeader(company)}
					{this.renderFormBody(company)}
					{this.renderFormFooter()}

				</Form>

				<PopupMessage
					show={!!showSaveMessage}
					title={`${company.name} Information`}
					body={showSaveMessage}
					onClose={this.onDismissPopup}
				/>

			</div>
		);
	}

	renderFormBody(company: CompanyDetails) {
		// setup
		const body = document.getElementById('ReportsPage-body');
		const style = {"maxHeight": body ? body.clientHeight - 160 : 600};

		// render
		return (
			<div className="form-body scroll-y" style={style}>
				<div className='form-block-label'>App Settings:</div>

				<Form.Group controlId="logo" className="form-field">
					<Form.Label className="field-md-label">Logo:</Form.Label>
					<Form.Control
						className="form-logo" type="image"
						src={company.logo}
					/>
				</Form.Group>
				<Form.Group controlId="logo" className="form-field">
					<Form.Label className="field-md-label"></Form.Label>
					<Form.Control
						className="field-md-value form-control" type="url"
						value={company.logo}
					/>
				</Form.Group>

				<div className='form-block'>
					<div className='form-column'>
						<Form.Group controlId="btnColor" className="form-field">
							<Form.Label className="field-md-label">Button Color:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="color"
								defaultValue={company.btnColor}
							/>
						</Form.Group>
						<Form.Group controlId="talkNumber" className="form-field">
							<Form.Label className="field-md-label">Talk Number:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="tel"
								defaultValue={company.talkNumber}
							/>
						</Form.Group>
						<Form.Group controlId="textNumber" className="form-field">
							<Form.Label className="field-md-label">Text Number:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="tel"
								defaultValue={company.textNumber}
							/>
						</Form.Group>

					</div>
				</div>
			</div>
		)
	}

	renderFormFooter() {
		// setup

		return (
			<div className="form-footer">
				<Button className="btn form-btn" variant="forgot" size="sm" onClick={this.onFormClose}>
					Close
				</Button>
				<Button type="submit" className="btn form-btn" size="sm">
					Update
				</Button>
			</div>
		)
	}

	renderFormHeader(company: CompanyDetails) {
		return (
			<div className="form-header">
				{company.name}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.onFormClose}>
					X
				</Button>
			</div>
		)
	}
}

