// react
import React from "react";
import {Button, Form} from "react-bootstrap";

// local
import {searchFilter} from "../types";

/**
 * Info
 */

interface InfoProps {
	clientId: number,
	title: string,
	close: () => void,
}

interface InfoState {
}

export class SearchForm extends React.Component<InfoProps, InfoState> {

	constructor(props: InfoProps) {
		super(props);

		// Register the callbacks.
		this.onFormClear = this.onFormClear.bind(this);
		this.onFormClose = this.onFormClose.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount = async () => {
	}

	onFormClear = async (event: any) => {
		// setup
		event.preventDefault(); // prevent page from reloading
		const form = event.currentTarget;

		form['first_name'].value = '';
		form['last_name'].value = '';
		form['email'].value = '';
		form['phone'].value = '';
		form['postcode'].value = '';
		form['order_id'].value = 0;
		form['course_id'].value = 0;
		form['course_title'].value = '';
		form['birth'].value = '';
		form['license'].value = '';
		form['ssnum'].value = '';

		searchFilter.clearFilters();
		this.props.close();
	}

	onFormClose = async (event: any) => {
		// setup
		event.preventDefault(); // prevent page from reloading
		this.props.close();
	}

	onFormSubmit = async (event: any) => {
		// setup
		event.preventDefault(); // prevent page from reloading
		const form = event.currentTarget;

		const filters = {
			first_name: form['first_name'].value,
			last_name: form['last_name'].value,
			email: form['email'].value,
			phone: form['phone'].value,
			postcode: form['postcode'].value,
			order_id: parseInt(form['order_id'].value),
			course_id: parseInt(form['course_id'].value),
			course_title: form['course_title'].value,
			birth: form['birth'].value,
			license: form['license'].value,
			ssnum: form['ssnum'].value
		}
		searchFilter.setFilters(filters);

		this.props.close();
	}

	render() {
		// setup
		const {title} = this.props;

		// render
		return (
			<div id="SearchForm">
				<Form className="form-popup" onSubmit={this.onFormSubmit} onReset={this.onFormClear}>

					{this.renderFormHeader(title)}
					{this.renderFormBody()}
					{this.renderFormFooter()}

				</Form>
			</div>
		);
	}

	renderFormBody() {
		// setup
		const body = document.getElementById('ReportsPage-body');
		const style = {"maxHeight": body ? body.clientHeight - 160 : 600};

		// render
		return (
			<div className="form-body scroll-y" style={style}>

				<div className='form-block-label'>All fields are optional.</div>
				<br/>
				<div className='form-block'>

					<div className='form-column'>
						<Form.Group controlId="first_name" className="form-field">
							<Form.Label className="field-md-label">First Name:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.first_name}
							/>
						</Form.Group>
						<Form.Group controlId="last_name" className="form-field">
							<Form.Label className="field-md-label">Last Name:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.last_name}
							/>
						</Form.Group>

						<div className='form-block-separator'></div>
						<Form.Group controlId="postcode" className="form-field">
							<Form.Label className="field-md-label">Zip Code:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.postcode}
							/>
						</Form.Group>
						<Form.Group controlId="email" className="form-field">
							<Form.Label className="field-md-label">Email:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.email}
							/>
						</Form.Group>
						<Form.Group controlId="phone" className="form-field">
							<Form.Label className="field-md-label">Phone:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.phone}
							/>
						</Form.Group>
					</div>

					<div className='form-column'>
						<Form.Group controlId="order_id" className="form-field">
							<Form.Label className="field-md-label">Order ID:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.order_id}
							/>
						</Form.Group>
						<Form.Group controlId="course_id" className="form-field">
							<Form.Label className="field-md-label">Course ID:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.course_id}
							/>
						</Form.Group>
						<Form.Group controlId="course_title" className="form-field">
							<Form.Label className="field-md-label">Course Title:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.course_title}
							/>
						</Form.Group>

						<div className='form-block-separator'></div>
						<Form.Group controlId="birth" className="form-field">
							<Form.Label className="field-md-label">Date-Of-Birth:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.birth}
							/>
						</Form.Group>
						<Form.Group controlId="license" className="form-field">
							<Form.Label className="field-md-label">License #:</Form.Label>
							<Form.Control
								className="field-md-value form-control" type="text"
								defaultValue={searchFilter.license}
							/>
						</Form.Group>
					</div>
				</div>
			</div>
		)
	}

	renderFormFooter() {
		// render
		return (
			<div className="form-footer">
				<Button type="reset" className="btn form-btn" variant="forgot" size="sm">
					Clear
				</Button>
				<Button type="submit" className="btn form-btn" variant="forgot" size="sm">
					Search
				</Button>
			</div>
		)
	}

	renderFormHeader(title: string) {
		return (
			<div className="form-header">
				{title}
				<Button id='ClosePopup' variant="forgot" className="btn form-btn" size="sm" onClick={this.onFormClose}>
					X
				</Button>
			</div>
		)
	}
}

