// react
import React from "react";
import {RiChatPollLine} from "react-icons/ri";

// local
import {Client} from "../../types";
import {SurveyResponses} from "./SurveyResponses";
import {SurveySummary} from "./SurveySummary";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";

/**
 * SurveySection
 */

interface SectionProps {
	client: Client
	navigate: NavigateFunction,
}

interface SectionState {
	companyId: string
}

export class SurveySection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);

		this.state = {
			companyId: ""
		};

		// Register the callbacks.
		this.onSelectCompany = this.onSelectCompany.bind(this);
		this.onNavigateSurveys = this.onNavigateSurveys.bind(this);
	}

	onSelectCompany(companyId: string) {
		const {client} = this.props;
		client.setDepartment(companyId);
		this.setState({companyId: (this.state.companyId === companyId) ? "" : companyId});
	}

	onNavigateSurveys() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/surveys");
	}

	render() {
		// setup
		const {client} = this.props;
		const {companyId} = this.state;
		const summary = client.getPollSummary();
		const responses = client.getPollBreakdown(companyId);
		const range = client.getPollRange();

		// render
		if (!summary.length || !responses.length) {
			return null;
		}
		return (
			<div className='section'>
				<div className='section-header'>
					<RiChatPollLine className="section-icon" size={28}/>
					Survey Information
				</div>
				<div className="navigate-main">
					<Button variant="forgot" size="sm" onClick={this.onNavigateSurveys}>
						Monthly Responses
					</Button>
				</div>

				<div className="row">
					<div className="column">
						<SurveySummary
							startYear={range.startYear}
							startMonth={range.startMonth}
							endYear={range.endYear}
							endMonth={range.endMonth}
							summary={summary}
							onSelectCompany={this.onSelectCompany}
							companyId={companyId}
						/>
					</div>
					<div className="column">
						<SurveyResponses
							year={range.endYear}
							month={range.endMonth}
							responses={responses}
						/>
					</div>
				</div>

				<hr/>
			</div>
		)
	}
}

