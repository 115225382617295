// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {LocationSummary} from "../../types";

/**
 * LocationStats
 */

interface LocationProps {
	summary: LocationSummary[]
}
interface LocationState {
}

export class LocationStats extends React.Component<LocationProps, LocationState> {

	render() {
		// setup
		const {summary} = this.props;

		// Construct the list.
		const head = [
			<tr>
				<th className="index">#</th>
				<th className="description">Location</th>
				<th className="value">users</th>
				<th className="value">participants</th>
				<th className="percent">%</th>
			</tr>
		];

		let tUsers = 0;
		let tActivity = 0;
		let body = [];
		for (let i = 0; i < summary.length; i++) {
			// setup
			const item = summary[i];
			const id = 'location'+item.id;
			tUsers += item.users;
			tActivity += item.activity;

			// render
			body.push(<tr id={id}>
				<td className="index">{i}</td>
				<td className="description">{item.name}</td>
				<td className="value">{item.users}</td>
				<td className="value">{item.activity}</td>
				<td className="value">{item.activity_percent}</td>
			</tr>);
		}

		const tTotal = tUsers ? Math.ceil((tActivity / tUsers) * 100).toString() + '%' : 'n/a';
		const footer = [
			<tr>
				<td className="index"></td>
				<td className="total">total</td>
				<td className="value">{tUsers.toLocaleString()}</td>
				<td className="value">{tActivity.toLocaleString()}</td>
				<td className="percent">{tTotal}</td>
			</tr>
		]

		// render
		return (
			<div id="LocationStats">
				<h4 className="title">Engagement by Location</h4>
				<Table id="LocationStats-Table" striped bordered responsive>
					<thead className="locations">{head}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		);
	}

}

