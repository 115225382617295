// react
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from 'moment';

export function formatPhoneNumber(phone: string) {
	if (!phone) {
		return '';
	}
	const cleaned = ('' + phone).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		let intlCode = (match[1] ? '+1 ' : '');
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

export function fridaysInMonth(y: number, m: number) {
	const days = new Date(y, m,0).getDate();
	let fridays = [ 6 - (new Date( m +'/01/'+ y ).getDay()) ];
	for (let i = fridays[0] + 7; i < days; i += 7) {
		fridays.push( i );
	}
	return fridays.length;
}

export function getDaysInMonth(y: number, m: number) {
	return (m===2) ? (y & 3 || (!(y%25) && y & 15) ? 28 : 29) : 30 + (m+(m>>3)&1);
}

export function timestampToStrDate(timestamp: number, includeSeconds: boolean) {
	// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
	if (includeSeconds) {
		return !!timestamp ? moment(timestamp * 1000).format('MM/DD/YY h:mm:ss a') : '';
	}
	return !!timestamp ? moment(timestamp * 1000).format('MM/DD/YY') : '';
}

export function timestampToDuration(timestamp: number) {
	let strTime = '';
	let seconds = timestamp;
	if (seconds >= 3600) {
		const hours = Math.floor(seconds / 3600);
		strTime += hours.toString().padStart(2, '0');
		seconds -= (hours * 3600);
	} else {
		strTime += '00';
	}
	if (seconds > 60) {
		const minutes = Math.floor(seconds / 60);
		strTime += ':' + minutes.toString().padStart(2, '0');
		seconds -= (minutes * 60);
	} else {
		strTime += ':00';
	}
	//strTime += ':' +  seconds.toString().padStart(2, '0');

	return strTime;
}

/**
 * withNavigation - for use with routing components.
 * @param Component
 */
export function withNavigation(Component: React.ComponentType<any>) {

	const WithNavigation = (props: any) => {
		const navigate = useNavigate();
		const params = useParams();
		return <Component {...props} navigate={navigate} params={params} />;
	}

	return WithNavigation;
}

export function nameToAcronymn(name:string) {
	let acronymn = "";
	const data = name.split(" ");
	for (let i = 0; i < data.length; i++) {
		acronymn += data[i][0];
	}
	return acronymn;
}

export function firstLastToName(first: string | null, last: string | null) {
	if (last && first) {
		return last + ', ' + first;
	} else if (last) {
		return last;
	}
	return first;
}

export function fieldIdToLabel(fieldId: string) {
	if (fieldId === 'id'){
		return 'ID';
	}
	let str = fieldId.toLowerCase().split('_');
	str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1);
	return str.join(' ');
}
