// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {PollResponse} from "../../types";

/**
 * Course Views
 */

interface ViewsProps {
	year: number,
	month: number,
	responses: PollResponse[],
	className?: string
}

export const SurveyResponses = (props: ViewsProps) => {

	// setup
	const {responses, year, month, className} = props;

	// Construct the list.
	const header = [
		<tr key='respnse-company-header'>
			<th>#</th>
			<th>Who</th>
			<th className='description'>Why</th>
		</tr>
	];

	let company = null;
	let subcategory = null;
	let body = [];
	for (let i = 0; i < responses.length; i++) {
		// setup
		const item = responses[i];
		if (item.year !== year || item.month !== month) {
			continue;
		}
		const key = 'result-'+i;

		// render
		if (item.company !== company) {
			company = item.company;
			subcategory = null;
			body.push(<tr key={'response-company-'+i}>
				<td className="location" colSpan={6}>{company}</td>
			</tr>);
		}
		if (item.question !== subcategory) {
			subcategory = item.question;
			body.push(<tr key={'response-question-'+i} className='subcategory'>
				<td className="subcategory" colSpan={6}>{subcategory}</td>
			</tr>);
		}
		body.push(<tr key={key}>
			<td>{item.total}</td>
			<td>{item.who}</td>
			<td className='description'>{item.why}</td>
		</tr>);
	}

	// render
	return (
		<div id="Responses" key="Responses" className={className ? className : ''}>
			<h4 className="title">Response Details ({month+'/'+(year-2000)})</h4>
			<Table id="Responses-Table" striped bordered responsive>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
			</Table>
		</div>
	);

}

