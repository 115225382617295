// react
import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";

// local
import {ActivitySummary, CURRENT_MONTH, CURRENT_YEAR, TOOLTIP_STYLE} from "../../types";

/**
 * Annual Engagement
 */

interface EngagementsProps {
	chartId: string,
	chartName: string,
	summary: ActivitySummary[],
	ctlMonths: string[]
}
interface EngagementsState {
}

export class AnnualEngagements extends React.Component<EngagementsProps, EngagementsState> {

	tooltip = ({ active, payload }: any) => {
		// setup
		const year2 = CURRENT_YEAR;
		const year1 = year2 - 1;

		// render
		if (active && payload && payload.length) {
			return (
				<div className="tip-custom">
					<div className="tip-title">{payload[0].payload.name}</div>
					{payload[0].value ?
						<div className="tip-last-year">{`${year1.toString()}: ${payload[0].value}`}</div> :
						<div/>}
					{payload[1].value || !payload[0].value ?
						<div className="tip-this-year">{`${year2.toString()}: ${payload[1].value}`}</div> :
						<div/>}
				</div>
			);
		}
		return null;
	};

	yAxis = (tickItem: any) => {
		return tickItem.toString();
	};

	render() {
		// Construct the list.
		const {chartName, summary, ctlMonths} = this.props;
		const year2 = CURRENT_YEAR;
		const year1 = year2 - 1;

		let stats = [];
		let month = (CURRENT_MONTH < 12) ? CURRENT_MONTH + 1 : CURRENT_MONTH;
		for (let i = 0; i < 12; i++) {
			const stat1 = summary.find(s => s.year === year1 && s.month === month);
			const stat2 = summary.find(s => s.year === year2 && s.month === month);
			stats.push({name: ctlMonths[month-1], year1: stat1 ? stat1.total : 0, year2: stat2 ? stat2.total : 0});
			if (++month > 12) {
				month = 1;
			}
		}

		// render
		return (
			<div id="AnnualStats">
				<h4 className="title">{chartName}</h4>
				<ResponsiveContainer width="100%" height={300}>
					<BarChart width={200} height={100} data={stats}>
						<CartesianGrid strokeDasharray="3 3" vertical={false}/>
						<Bar dataKey={'year1'} name={year1.toString()} fill="#88C2A7"/>
						<Bar dataKey={'year2'} name={year2.toString()} fill="#4D77FF"/>
						<YAxis
							label={{ value: 'Monthly Total', angle: -90, position: 'insideLeft' }}
							tickFormatter={this.yAxis}
						/>
						<Legend />
						<XAxis
							dataKey="name"
							fontFamily="sans-serif"
							label={{ value: ''}}
							dy='25'
						/>
						<Tooltip content={this.tooltip} wrapperStyle={TOOLTIP_STYLE} cursor={{fill: '#3880ff54'}}/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		);
	}

}

