// react
import React from 'react';
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {Dropdown} from "react-bootstrap";
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import {FiSettings} from "react-icons/fi";
import {RiGroupLine} from "react-icons/ri";
import {RiListSettingsLine} from "react-icons/ri";

// local
import {APPMENU_ICON_SIZE, SIDEBAR_ICON_SIZE} from "../types";

/**
 * AppMenu
 */

type ToggleProps = {
	children?: React.ReactNode;
	onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const AppSettingsIcon = React.forwardRef((props: ToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
	<div className='sidebar-icon-wrapper static' onClick={(e: any) => {
		e.preventDefault();
		props.onClick(e);
		}}>
		<FiSettings className="sidebar-icon" size={SIDEBAR_ICON_SIZE}/>
		<div className="sidebar-icon-text">Settings</div>
	</div>
));

interface SettingsProps {
	clientName: string
	onAddClient: () => void
	onEditClientInfo: () => void
	onEditClientDesign: () => void
}
interface SettingsState {
}

export class AppSettings extends React.Component<SettingsProps, SettingsState> {

	constructor(props: SettingsProps) {
		super(props);

		this.onSelectItem = this.onSelectItem.bind(this);
	}

	onSelectItem(eventKey: any, event: Object) {
		// setup
		const {onAddClient, onEditClientInfo, onEditClientDesign} = this.props;

		// Process the selection.
		if (eventKey === 'add_client') {
			onAddClient();
		} else if (eventKey === 'edit_client_info') {
			onEditClientInfo();
		} else if (eventKey === 'edit_client_design') {
			onEditClientDesign();
		}
	}

	render() {
		// setup
		const {clientName} = this.props;

		// render
		return (
			<Dropdown id="AppSettings" onSelect={this.onSelectItem}>

				<Dropdown.Toggle as={AppSettingsIcon}/>

				<DropdownMenu id="AppPopupSettings">
					<Dropdown.Item className='popup-icon-wrapper' eventKey="edit_client_info">
						<RiGroupLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">{`${clientName}`} Info</div>
					</Dropdown.Item>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="edit_client_design">
						<RiListSettingsLine className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">{`${clientName}`} Design</div>
					</Dropdown.Item>
					<hr/>
					<Dropdown.Item className='popup-icon-wrapper' eventKey="add_client">
						<AiOutlineUsergroupAdd className="popup-icon" size={APPMENU_ICON_SIZE}/>
						<div className="popup-icon-text">Add Client/Location</div>
					</Dropdown.Item>
				</DropdownMenu>

			</Dropdown>
		);
	}
}
