// react
import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";

// local
import {
	AppMenu,
	Footer,
	Header,
} from "../components";
import {
	PageProps,
	withNavigation,
} from "../types";
import {SurveyResponses} from "../sections/survey/SurveyResponses";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction
	department: string
}

const Subheader = (props: SubheaderProps) => {

	// setup
	function onReports() {
		// setup
		const {navigate} = props;

		// Go to the engagements page.
		navigate("/reports");
	}

	return (
		<div className="subheader">
			<div className="app-subtitle">{`${props.department} Survey Details`}</div>
			<div className="navigate-sub">
				<Button variant="forgot" size="sm" onClick={onReports}>
					Back to Reporting
				</Button>
			</div>

		</div>
	);
}

/**
 * SurveysPage
 */

interface SurveysProps extends PageProps {
}
interface SurveysState {
}

@inject("user")
@inject("client")
@observer
class SurveysPageComponent extends React.Component<SurveysProps, SurveysState> {

	constructor(props: SurveysProps) {
		super(props);

		// Register the callbacks.
		this.onGoBack = this.onGoBack.bind(this);
		this.onLogout = this.onLogout.bind(this);
	}

	onGoBack() {
		// setup
		const {navigate} = this.props;
		navigate('/reports');
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	render() {
		// setup
		const {navigate, client, user} = this.props;
		const responses = client.getPollBreakdown(client.departmentId);
		const range = client.getPollRange();

		// render
		return (
			<div className="page">
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>
				<Subheader
					department={client.departmentId}
					navigate={navigate}
				/>

				<div className="body">
					<div className="row">
						<div className="column">
							<SurveyResponses
								year={range.endYear}
								month={range.endMonth-1}
								responses={responses}
								className={'full-height'}
							/>
						</div>
						<div className="column">
							<SurveyResponses
								year={range.endYear}
								month={range.endMonth}
								responses={responses}
								className={'full-height'}
							/>
						</div>
					</div>
					<hr/>
				</div>

				<Footer/>
			</div>
		);
	}

}

export const SurveysPage = withNavigation(SurveysPageComponent);
