// react
import React from "react";
import {Button, Table} from "react-bootstrap";

// local
import {
	FreshdeskCompany,
	FreshdeskTicket,
	FRESHDESK_PAGE_SIZE
} from "../../types";

/**
 * FreshdeskEngagement
 */

interface EngagementProps {
	companies: FreshdeskCompany[],
	item: FreshdeskTicket,
}

const FreshdeskEngagement = (props: EngagementProps) => {

	// setup
	const {companies, item} = props;
	const priorities = [
		'low',
		'medium',
		'high',
		'urgent'
	];
	const statuses = [
		'unknown',
		'open',
		'pending',
		'resolved',
		'closed',
		'waiting'
	];

	// item details
	const tags = item.tags.join(',');
	const status = item.status ? statuses[item.status-1] : '';
	const priority = priorities[item.priority-1];
	const options:Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
	const timestamp = item.created.toLocaleString('en-US', options);
	const company = companies.find(c => {
		return c.id === item.company_id;
	});

	const typeClass = item.type ? 'type' : 'type missing';
	const categoryClass = item.category ? 'category' : 'category missing';
	const companyClass = company ? 'company' : 'company missing';
	const companyName = company ? company.name : (item.company_id ? `*${item.company_id}*` : '*anonymous*');
	const href = 'https://blunovus.freshdesk.com/a/tickets/'+item.id;

	// render
	return (
		<tr id={'t-'+item.id} key={'t-'+item.id}>
			<td className={"ticket"}><a href={href} target="_blank" rel="noreferrer">{item.id}</a></td>
			<td className="date">{timestamp}</td>
			<td className={companyClass}>{companyName}</td>
			<td className={typeClass}>{item.type ? item.type : '*missing*'}</td>
			<td className={categoryClass}>{item.category ? item.category : '*undefined*'}</td>
			<td className="status">{status}</td>
			<td className="outcome">{item.outcome}</td>
			<td className="priority">{priority}</td>
			<td className="summary">{item.description}</td>
			<td className="tags">{tags}</td>
		</tr>
	);
}

/**
 * FreshdeskEngagements
 *      Note: Freshdesk API interface https://developers.freshdesk.com/api/
 */

interface EngagementsProps {
	companies: FreshdeskCompany[],
	tickets: FreshdeskTicket[],
	referrals: number,
	ticketPage: number,
	ticketCount: number,
	pageNext: () => void,
	pagePrev: () => void,
}
interface EngagementsState {
	referral: number
}

export class FreshdeskEngagements extends React.Component<EngagementsProps, EngagementsState> {

	render() {
		// setup
		const {companies, tickets, ticketCount, ticketPage, pageNext, pagePrev, referrals} = this.props;

		// Construct the list.
		const start = (ticketPage * FRESHDESK_PAGE_SIZE);
		const end = (start + FRESHDESK_PAGE_SIZE) > ticketCount ? ticketCount : (start + FRESHDESK_PAGE_SIZE - 1);
		const summary = <Button className="btn-page" variant="forgot" size="sm">{start} - {end} of {ticketCount}</Button>;
		const prev = ticketPage
			? <Button className="btn-page" variant="forgot" size="sm" onClick={pagePrev}>&lt;&lt;</Button>
			: '';
		const next = (end < ticketCount)
			? <Button className="btn-page" variant="forgot" size="sm" onClick={pageNext}>&gt;&gt;</Button>
			: '';

		let body = [];
		if (start < end) {
			for (let i = start; i < end; i++) {
				const item = tickets[i];
				body.push(<FreshdeskEngagement companies={companies} item={item}/>);
			}
		} else {
			body.push(<tr id={'empty'} key={'empty'}>
				<td className="summary warning" colSpan={2}>No Activity</td>
			</tr>);
		}

		// render
		return (
			<div id="FreshdeskEngagements">
				<Table id="FreshdeskEngagements-Table" striped bordered responsive>
					<thead>
						<th className="ticket">#</th>
						<th className="date">Created</th>
						<th className="company">Company</th>
						<th className="type">Type</th>
						<th className="category">Employee</th>
						<th className="status">Status</th>
						<th className="outcome">Outcome</th>
						<th className="priority">Priority</th>
						<th className="summary">Summary</th>
						<th className="tags">Tags</th>
					</thead>
					<tbody>
						{body}
					</tbody>
					<tfoot><tr>
						<td colSpan={6}/>
						<td className='outcome'>{`referrals=${referrals}`}</td>
						<td></td>
						<td className="btn-page">{prev} {summary} {next}</td>
						<td></td>
					</tr></tfoot>
				</Table>
			</div>
		);
	}

}

