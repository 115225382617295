// react
import React from "react";
import {Table} from "react-bootstrap";
import {AiOutlineInfoCircle} from "react-icons/ai";

// local
import {ActivitySummary} from "../../types";
import {PopupMessage} from "../../components";

/**
 * Course Categories
 */

interface SummaryProps {
	categories: ActivitySummary[],
	onSelectActivity: (activityId: string) => void,
	activityId: string
}
interface SummaryState {
	showInfoMessage: boolean,
	infoTitle: string,
	infoBody: any,
}

export class CoursesSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			showInfoMessage: false,
			infoTitle: "Navigating Summary of Courses",
			infoBody: <div>Select a course by clicking the list item. Once selected, the 'Viewing Details' list will show only that course, by location. <br/>Revert back to the global view by re-clicking the selected course.</div>
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		let eTotal = 0;
		let sTotal = 0;
		let cTotal = 0;
		const {categories, activityId, onSelectActivity} = this.props;
		const {showInfoMessage, infoTitle, infoBody} = this.state;

		// Construct the list.
		const header = [
			<tr key='courses-header'>
				<th className="description">Course</th>
				<th className="value">enrolled</th>
				<th className="value">started</th>
				<th className="percent">%</th>
				<th className="value">finished</th>
				<th className="percent">%</th>
			</tr>
		];

		const body = categories.length ?
			categories.map(item => {
				// setup
				const key = 'summary'+eTotal;
				eTotal += item.total;
				const title = item.activity_title ? item.activity_title : "";
				const selected = (title === activityId) ? 'selected' : 'selectable';

				const started = item.started ? item.started : 0;
				sTotal += started;
				const sPercent = Math.ceil(item.started_percent) + '%';

				const completed = item.completed ? item.completed : 0;
				cTotal += completed;
				const cPercent = Math.ceil(item.completed_percent) + '%';

				// render
				return (<tr
					key={key}
					className={selected}
					onClick={() => onSelectActivity(title)}
				>
					<td className="description">
						{item.activity_title}
					</td>
					<td className="value">{item.total.toLocaleString()}</td>
					<td className="value">{started.toLocaleString()}</td>
					<td className="percent">{sPercent}</td>
					<td className="value">{completed.toLocaleString()}</td>
					<td className="percent">{cPercent}</td>
				</tr>);
			}) :
			<tr key={'courses-empty'}>
				<td className="description warning" colSpan={6}>No Activity</td>
			</tr>;

		const sPercent = eTotal ? Math.ceil((sTotal / eTotal) * 100) + '%' : 'n/a';
		const cPercent = eTotal ? Math.ceil((cTotal / eTotal) * 100) + '%' : 'n/a';
		const footer = <tr key='courses-footer'>
			<td className="total">total</td>
			<td className="value">{eTotal.toLocaleString()}</td>
			<td className="value">{sTotal.toLocaleString()}</td>
			<td className="percent">{sPercent}</td>
			<td className="value">{cTotal.toLocaleString()}</td>
			<td className="percent">{cPercent}</td>
		</tr>;

		// render
		return (
			<div id="CoursesSummary">
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">Summary of Courses</h4>

				<Table id="CoursesSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>

				<PopupMessage
					show={!!showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		)
	}

}
