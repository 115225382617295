// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {ActivitySummary} from "../../types";

/**
 * Course Categories
 */

interface CategoriesProps {
	categories: ActivitySummary[]
}
interface CategoriesState {
	category: string
}

export class CourseCategories extends React.Component<CategoriesProps, CategoriesState> {

	constructor(props: CategoriesProps) {
		super(props);

		this.state = {
			category: ""
		}

		// Register the callbacks.
		this.onSelectCategory = this.onSelectCategory.bind(this);
	}

	onSelectCategory(category: string) {
		this.setState({category});
	}

	// setup
	render() {
		// setup
		let total = 0;
		const {categories} = this.props;

		// Construct the list.
		const header = [
			<tr key='category-header'>
				<th className="description">Category</th>
				<th className="value">views</th>
			</tr>
		];
		const body = categories.length ?
			categories.map(item => {
				// setup
				total += item.total;
				const key = 'category-'+item.activity_category;

				// render
				return (
					<tr key={key}
					    className="category selectable"
					    onClick={() => this.onSelectCategory(item.activity_category)}
					>
						<td className="description">
							{item.activity_title}
						</td>
						<td className="value">{item.total.toLocaleString()}</td>
					</tr>);
			}) :
			<tr key={'category-empty'}>
				<td className="description warning" colSpan={2}>No Activity</td>
			</tr>;
		const footer = <tr key='category-footer'>
			<td className="total">total</td>
			<td className="value">{total.toLocaleString()}</td>
		</tr>;

		// render
		return (
			<div id="CourseCategories" key="CourseCategories">
				<h4 className="title">Course Categories</h4>
				<Table id="CourseCategories-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		)
	}


}
