// Global Definitions...
import "./wagd.css";

/* For reports and recharts */
const date = new Date();
export const CURRENT_YEAR = date.getFullYear();
export const CURRENT_MONTH = date.getMonth() + 1;

export const UTC_DATE_FORMAT = 'YYYY-MM-DD';
export const LOC_DATE_FORMAT = 'MM/DD/YY';
export const LOC_SDATE_FORMAT = 'MM/DD';

/* styles */
export const TOOLTIP_STYLE = {padding: '2px 10px', backgroundColor: '#fff', borderRadius: '8px', border: '1px solid #8b61ff'};
export const LEGEND_STYLE = {display: "inline-block"};

/* Roles */
export const ROLE_ADMIN = 'admin';
export const ROLE_COMPANY_MANAGER = 'companymanager';
export const ROLE_DEPARTMENT_MANAGER = 'departmentmanager';
export const ROLE_USER = 'user';

/* Other */
export const SIDEBAR_ICON_SIZE = 28;
export const APPMENU_ICON_SIZE = 22;

/* For Google */
//const GOOGLE_TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID

/* For Freshdesk */
export const FRESHDESK_PAGE_SIZE = 30;
