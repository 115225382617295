// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {
	Client,
	CouponInfo, timestampToStrDate
} from "../../types";

/**
 * Coupons Summary
 */

interface SummaryProps {
	client: Client
	coupons: CouponInfo[],
	couponId: number,
	onSelectItem: (itemId: number) => void
}
interface SummaryState {
}


export class CouponsSummary extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
		}
	}

	render() {
		// setup
		const {coupons, couponId, onSelectItem} = this.props;

		// Construct the list.
		const header = [
			<tr key='sales-company-header'>
				<th className="value">Created</th>
				<th className="description">Code</th>
				<th className="value">Type</th>
				<th className="value">Amount</th>
				<th className="value">Used</th>
				<th className="value">Limit</th>
			</tr>
		];

		const body = [];
		for (let i = 0; i < coupons.length; i++) {
			// coupon
			const item = coupons[i];
			const created = timestampToStrDate(item.created, false);
			const selected = (item.id === couponId) ? 'selectable selected' : 'selectable';
			let type, limit;
			if (item.coupon_type === 'percent') {
				type = 'percent';
				limit = item.coupon_limit ? item.coupon_limit : '∞';
			} else if (item.coupon_type === 'smart_coupon') {
				type = 'smart';
				limit = 1;
			} else {
				type = 'fixed';
				limit = item.coupon_limit ? item.coupon_limit : '∞';
			}
			const amount = item.coupon_amount.toLocaleString();

			body.push(
				<tr
					key={'coupon-'+i}
					className={selected}
					onClick={() => onSelectItem(item.id)}
				>
					<td className='date'>{created}</td>
					<td className='description'>{item.coupon_id}</td>
					<td className='value'>{type}</td>
					<td className='value'>{amount}</td>
					<td className='value'>{item.coupon_use}</td>
					<td className='value'>{limit}</td>
				</tr>
			);
		}

		// render
		return (
			<div id="CouponsSummary">
				<Table id="CouponsSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
				</Table>
			</div>
		)
	}

}
