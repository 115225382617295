// react
import React from "react";
import {sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import {applySnapshot, flow, Instance, types} from "mobx-state-tree"
import {collection, documentId, getDocs, query, where} from "firebase/firestore";
import axios from "axios";
import packageJson from '../../package.json';
import {Dropdown} from "react-bootstrap";

// local
import {fbAuth, fbDB} from "./firebase";
import {
	CURRENT_MONTH,
	CURRENT_YEAR,
	ROLE_ADMIN,
	ROLE_COMPANY_MANAGER,
	ROLE_DEPARTMENT_MANAGER,
	ROLE_USER
} from "./variables";

export const webservice = axios.create({
	baseURL: (packageJson.globals.__DEV__) ? packageJson.globals.__DEV_API__ : packageJson.globals.API_URL,
	url: '/webservice/rest/server.php',
	method: 'POST',
	withCredentials: false,
	//headers: {'Cookie': 'XDEBUG_SESSION=13068'}
});

/**
 * General Resources
 */

export interface NameValuePair {
	name: string,
	value: string | number,
}

const KeyValueInfo = types.model("KeyValuePair", {
	id: types.string,
	name: types.string,
	value: types.union(types.number, types.string),
	color: types.maybeNull(types.string)
});
export type KeyValuePair = Instance<typeof KeyValueInfo>;

export const PageNavInfo = types.model("PageNavInfo", {
	// general
	category: types.string,
	coupons: types.string,
	course: types.string,
	engagement: types.string,
	insights: types.string,
	location: types.string,
	sales: types.string,
	settings: types.string,
	students: types.string,
	survey: types.string,
	// student
	return: types.string,
	orders: types.string,
	courses: types.string,
	exams: types.string,
	certificates: types.string,
	validation: types.string,
	logins: types.string,
}).actions(self => {

	function init(values: NameValuePair[]) {
		values.forEach(item => {
			// @ts-ignore
			self[item.name] = item.value;
		});
	}

	function toggle(section: string) {
		// @ts-ignore
		self[section] = (self[section] === 'show') ? 'selected' : 'show';
		applySnapshot(self, {...self});
	}

	return {
		init,
		toggle
	}
}).views(self => ({

	selected() {
		return {
			category: self.category === 'selected',
			coupons: self.coupons === 'show',
			course: self.course === 'selected',
			engagement: self.engagement === 'selected',
			insights: self.insights === 'selected',
			location: self.location === 'selected',
			sales: self.sales === 'selected',
			settings: self.settings === 'selected',
			students: self.students === 'selected',
			survey: self.survey === 'selected',
			// student
			return: self.return === 'selected',
			orders: self.orders === 'show',
			courses: self.courses === 'selected',
			exams: self.exams === 'show',
			certificates: self.certificates === 'selected',
			validation: self.validation === 'show',
			logins: self.logins === 'show',
		}
	},

	show() {
		return {
			category: !!self.category,
			coupons: !!self.coupons,
			course: !!self.course,
			engagement: !!self.engagement,
			insights: !!self.insights,
			location: !!self.location,
			sales: !!self.sales,
			settings: !!self.settings,
			students: !!self.students,
			survey: !!self.survey,
			// student
			return: !!self.return,
			orders: !!self.orders,
			courses: !!self.courses,
			exams: !!self.exams,
			certificates: !!self.certificates,
			validation: !!self.validation,
			logins: !!self.logins,
		}
	}

}));
export type PageNav = Instance<typeof PageNavInfo>;

/**
 * Moodle resources
 */

export const ActivitySummaryInfo = types.model("ActivitySummaryInfo", {
	year: types.number,
	month: types.number,
	activity_category: types.string,
	activity_type: types.string,
	activity_title: types.maybeNull(types.string),
	total: types.number,
	started: types.number,
	started_percent: types.number,
	completed: types.number,
	completed_percent: types.number,
	color: types.maybeNull(types.string)
})
export type ActivitySummary = Instance<typeof ActivitySummaryInfo>;

export const CareSummaryInfo = types.model("CareSummaryInfo", {
	activity_name: types.string,
	all_total: types.number,
	all_percent: types.number,
	company_total: types.number,
	company_percent: types.number
})
export type CareSummary = Instance<typeof CareSummaryInfo>;

export const LocationSummaryInfo = types.model("LocationSummaryInfo", {
	id: types.number,
	name: types.string,
	users: types.number,
	activity: types.number,
	activity_percent: types.string
})
export type LocationSummary = Instance<typeof LocationSummaryInfo>;

export const MdlResource = types.model("MdlResource", {
	activity_id: types.number,
	activity_type: types.string,
	activity_category: types.string,
	activity_name: types.string,
	activity_title: types.string,
	engagements: types.number,
	ave_time: types.number
});
export type Resource = Instance<typeof MdlResource>;

export const MdlResourceGroup = types.model("MdlResourceGroup", {
	care: types.array(MdlResource),
	hope: types.array(MdlResource),
	training: types.array(MdlResource),
	page: types.array(MdlResource),
	course: types.array(MdlResource),
	post: types.array(MdlResource),
	story: types.array(MdlResource),
	app: types.array(MdlResource),
	site: types.array(MdlResource),
});
export type ResourceGroup = Instance<typeof MdlResourceGroup>;

export const PollResponseInfo = types.model("PollResponseInfo", {
	id: types.number,
	year: types.number,
	month: types.number,
	company: types.string,
	question: types.string,
	who: types.string,
	why: types.string,
	total: types.number
})
export type PollResponse = Instance<typeof PollResponseInfo>;

export const PollSummaryInfo = types.model("PollSummaryInfo", {
	id: types.string,
	year: types.number,
	month: types.number,
	company: types.string,
	title: types.string,
	started: types.number,
	started_percent: types.number,
	completed: types.number,
	completed_percent: types.number,
	total: types.number
})
export type PollSummary = Instance<typeof PollSummaryInfo>;

export const CourseInfoModel = types.model("CourseInfoModel", {
	id: types.number,
	company_id: types.number,
	company_name: types.string,
	user_id: types.number,
	user_last_name: types.string,
	user_first_name: types.string,
	email: types.string,
	phone: types.string,
	course_id: types.number,
	course_title: types.string,
	enrolled: types.number,
	started: types.number,
	completed: types.number,
	mod_progress: types.number,
	mod_completed: types.number,
	mod_total: types.number,
	total: types.number,
})
export type CourseInfo = Instance<typeof CourseInfoModel>;

export const SalesInfoModel = types.model("SalesInfoModel", {
	id: types.number,
	company_id: types.number,
	company_name: types.string,
	user_id: types.number,
	user_last_name: types.string,
	user_first_name: types.string,
	email: types.string,
	phone: types.string,
	postcode: types.string,
	created: types.number,
	order_id: types.number,
	order_item_id: types.number,
	order_item_name: types.string,
	qty: types.number,
	subtotal: types.number,
	coupon: types.string,
	discount: types.number,
	total: types.number,
	// moodle
	mdl_user_id: types.number
})
export type SalesInfo = Instance<typeof SalesInfoModel>;

export const CouponUseInfoModel = types.model("CouponUseInfoModel", {
	id: types.number,
	created: types.number,
	coupon_id: types.string,
	coupon_name: types.string,
	coupon_amount: types.number,
	user_id: types.number,
	user_email: types.string,
	user_last_name: types.string,
	user_first_name: types.string,
})
export type CouponUseInfo = Instance<typeof CouponUseInfoModel>;

export const CouponInfoModel = types.model("CouponInfoModel", {
	id: types.number,
	created: types.number,
	coupon_id: types.string,
	coupon_name: types.string,
	coupon_type: types.string,
	coupon_amount: types.number,
	coupon_use: types.number,
	coupon_limit: types.number,
	use: types.array(CouponUseInfoModel),
})
export type CouponInfo = Instance<typeof CouponInfoModel>;

export interface MdlItemCount {
	company_id: number,
	company_name: string,
	engagements: number,
	modified: boolean
}
export interface MdlResourceCount {
	census: MdlItemCount[],
	care: MdlItemCount[],
	hope: MdlItemCount[],
	app: MdlItemCount[],
	site: MdlItemCount[],
	training: MdlItemCount[],
	page: MdlItemCount[],
	course: MdlItemCount[],
	post: MdlItemCount[],
	story: MdlItemCount[],
}

/**
 * SystemInfo - This structure is used for mobx storage efficiency.
 */
export const SystemInfo = types.model("System", {
	// basic
	initialized: types.boolean,
	loading: types.boolean,
	// advanced
	appName: types.string,
	appLogo: types.string,
	appCopyright: types.string,
	apiUrl: types.string,
	apiKey: types.string,
	rootId: types.string,
	rootUrl: types.string,
	rootLogo: types.string,
	// runtime
	userid: types.string,
	token: types.string
}).actions(self => {

	function clear() {
		applySnapshot(self, {...self, userid: "", token: ""});
	}

	const generatePDF = flow(function* generatePDF(html: string) {
		const pdfservice = axios.create({
			baseURL: 'http://rpaulsen.iomad.com',
			url: '/local/lecticon/testpdf.php',
			method: 'POST',
			withCredentials: false,
		});

		const options = new FormData();
		options.append('html', html);

		const results = yield pdfservice.post('/local/lecticon/testpdf.php', options);
		return results;
	})

	function initialize() {
		// setup
		if (self.initialized) {
			return;
		}

		// Get the values.
		const query = window.location.search.substr(1).split('&');

		// Parse the query params, if any.
		let userid = "";
		let token = "";
		let rootUrl = self.rootUrl;
		for (let i = 0; i < query.length; ++i) {
			let parts = query[i].split('=', 2);
			if (parts[0] === 'userid') {
				userid = parts[1].toString();
			} else if (parts[0] === 'token') {
				token = parts[1];
			} else if (parts[0] === 'url') {
				rootUrl = parts[1];
			}
		}

		// Apply the results.
		applySnapshot(self, {...self, loading: false, userid, token, rootUrl}); // for protection
	}

	return {
		clear,
		initialize,
		generatePDF
	}

})
export type System = Instance<typeof SystemInfo>;

export const system = SystemInfo.create({
	// basic
	initialized: false,
	loading: false,
	// advanced
	appName: packageJson.globals.APP_NAME,
	appLogo: packageJson.globals.APP_LOGO,
	appCopyright: packageJson.globals.APP_COPYRIGHT,
	apiUrl: (packageJson.globals.__DEV__) ? packageJson.globals.__DEV_API__ : packageJson.globals.API_URL,
	apiKey: packageJson.globals.API_KEY,
	rootId: packageJson.globals.ROOT_ID,
	rootUrl: packageJson.globals.ROOT_URL,
	rootLogo: packageJson.globals.ROOT_LOGO,
	// runtime
	userid: '',
	token: ''
});

/**
 * ClientInfo - This structure is used for mobx storage efficiency.
 */

export const DepartmentInfo = types.model("DepartmentInfo", {
	id: types.string,
	name: types.string
})
export type Department = Instance<typeof DepartmentInfo>;

const CompanyInfo = types.model("CompanyInfo", {
	id: types.number,
	parentid: types.number,
	name: types.string,
	shortname: types.string,
	code: types.string,
	logo: types.string,
	census: types.number,
	departments: types.array(DepartmentInfo)
});
export type Company = Instance<typeof CompanyInfo>;

export interface CompanyDetails {
	// basic
	id: number,
	name: string,
	shortname: string,
	parentid: number,
	code: string,
	logo: string,
	// address
	address: string,
	city: string,
	postcode: string,
	country: string,
	locale: string,
	// contact
	contact_name: string,
	contact_email: string,
	contact_phone: string,
	// billing
	broker_name: string,
	broker_email: string,
	broker_phone: string,
	// billing
	billing_name: string,
	billing_email: string,
	billing_phone: string,
	// other
	census: number,
	office_census: number,
	other_census: number,
	status: string,
	industry: string,
	validfrom: number,
	validto: number,
	contract_level: string,
	pricing: string,
	primary_insurance: string,
	service: string,
	purpose: string,
	values: string,
	departments: Department[]
	// strings
	btnColor: string,
	talkNumber: string,
	textNumber: string,
}

export const ClientInfo = types.model("ClientInfo", {
	// data
	id: types.number,
	name: types.string,
	logo: types.string,
	url: types.string,
	departments: types.array(DepartmentInfo),
	departmentId: types.string,
	pageNavigation: PageNavInfo,
	// stats
	census: types.number,
	companyStats: types.array(ActivitySummaryInfo),
	locationStats: types.array(LocationSummaryInfo),
	annualStats: types.array(ActivitySummaryInfo),
	monthlyStats: types.array(ActivitySummaryInfo),
	careReason: types.array(CareSummaryInfo),
	careWho: types.array(CareSummaryInfo),
	courseSummary: types.array(ActivitySummaryInfo),
	courseBreakdown: types.array(ActivitySummaryInfo),
	pollSummary: types.array(PollSummaryInfo),
	pollBreakdown: types.array(PollResponseInfo),
	// status
	initialized: types.boolean,
	loading: types.boolean
}).volatile(_ => ({
	// sales
	sales: [] as SalesInfo[],
	salesCompanyId: '' as string,
	salesStartDate: '' as string,
	salesEndDate: '' as string,
	// courses
	courses: [] as CourseInfo[],
	coursesCompanyId: '' as string,
	coursesStartDate: '' as string,
	coursesEndDate: '' as string,
	// coupons
	coupons: [] as CouponInfo[],
	couponsCompanyId: '' as string,
	couponsStartDate: '' as string,
	couponsEndDate: '' as string,
})).actions(self => {

	function clearStats() {
		applySnapshot(self, defaultClient);
	}

	const fetchBnAnalytics = async (companyid: string, year: number, month: number) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'bn_get_company_analytics');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('year', year.toString());
		options.append('month', month.toString());

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			// setup
			const census = self.census ? self.census : 10;

			// fixups
			let company_summary = results.data.company_summary;
			for (let i = 0; i < company_summary.length; i++) {
				const activity_type = 'utilization';
				if (company_summary[i].activity_type === 'care') {
					const total = (company_summary[i].total / census) * 100;
					company_summary.push({
						...company_summary[i], activity_type, total,
						started: total, started_percent: 100, completed: total, completed_percent: 100
					});
				}
			}
			company_summary.push({
				year, month, activity_category: "", activity_type: 'census', total: census,
				started: census, started_percent: 100, completed: census, completed_percent: 100
			});

			// fixups
			let company_breakdown = results.data.company_breakdown;
			for (let i = 0; i < company_breakdown.length; i++) {
				const activity_type = 'utilization';
				if (company_breakdown[i].activity_type === 'care') {
					const total = (company_breakdown[i].total / census) * 120;
					company_breakdown.push({
						...company_breakdown[i], activity_type, total,
						started: total, started_percent: 100, completed: total, completed_percent: 100
					});
				}
			}

			return {
				company_summary,
				company_breakdown,
				care_reason: results.data.care_reason,
				care_who: results.data.care_who,
				course_summary: results.data.course_summary,
				course_breakdown: results.data.course_breakdown,
				poll_summary: results.data.poll_summary,
				poll_breakdown: results.data.poll_responses
			};
		}
		return {
			company_summary: [],
			company_breakdown: [],
			care_reason: [],
			care_who: [],
			course_summary: [],
			course_breakdown: [],
			poll_summary: [],
			poll_breakdown: []
		};
	}

	const fetchCouponAnalytics = flow(function* fetchCouponAnalytics(companyid: string, startDate: string, endDate: string) {
		// Check for existing courses.
		if (self.coupons.length && self.couponsCompanyId === companyid && self.couponsStartDate === startDate && self.couponsEndDate === endDate) {
			return self.coupons;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'wp_get_coupons');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		self.coupons = (results && results.status === 200) ? results.data : [];
		self.couponsCompanyId = companyid;
		self.couponsStartDate = startDate;
		self.couponsEndDate = endDate;
		return self.coupons;
	})

	const fetchCoursesAnalytics = flow(function* fetchCoursesAnalytics(companyid: string, startDate: string, endDate: string) {
		// Check for existing courses.
		if (self.courses.length &&  self.coursesCompanyId === companyid && self.coursesStartDate === startDate && self.coursesEndDate === endDate) {
			return self.courses;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_courses_details');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		self.courses = (results && results.status === 200) ? results.data : [];
		self.coursesCompanyId = companyid;
		self.coursesStartDate = startDate;
		self.coursesEndDate = endDate;
		return self.courses;
	})

	const fetchMdlAnalytics = async (companyid: string, year: number, month: number) => {
		// setup
		let wsfunction = 'mdl_get_company_analytics'; // TODO - TEMPORARY
		if (system.rootUrl.includes('haven')) {
			wsfunction = 'hh_get_company_analytics';
		} else if (system.rootUrl.includes('aaadrivingcourse')) {
			wsfunction = 'aaa_get_company_analytics';
		} else if (system.rootUrl.includes('washingtonagd')) {
			wsfunction = 'aaa_get_company_analytics';
		}
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', wsfunction);
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('year', year.toString());
		options.append('month', month.toString());

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			return {
				company_summary: results.data.company_summary,
				course_summary: results.data.course_summary,
				course_breakdown: results.data.course_breakdown,
				poll_summary: results.data.poll_summary,
				poll_breakdown: results.data.poll_responses
			};
		}
		return {
			company_summary: [],
			course_summary: [],
			course_breakdown: [],
			poll_summary: [],
			poll_breakdown: []
		};
	}

	const fetchSalesAnalytics = flow(function* fetchSalesAnalytics(companyid: string, startDate: string, endDate: string) {
		// Check for existing courses.
		if (self.sales.length && self.salesCompanyId === companyid && self.salesStartDate === startDate && self.salesEndDate === endDate) {
			return self.sales;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'wp_get_orders');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		self.sales = (results && results.status === 200) ? results.data : [];
		self.salesCompanyId = companyid;
		self.salesStartDate = startDate;
		self.salesEndDate = endDate;
		return self.sales;
	})

	const fetchStudentDetails = async (userid: number, orderid: number, courseid: number) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_student_details');
		options.append('moodlewsrestformat', 'json');
		options.append('userid', userid.toString());
		options.append('orderid', orderid.toString());
		options.append('courseid', courseid.toString());

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the stats.
		if (results && results.status === 200) {
			return results.data;
		}
		return null;
	}

	const generateReport = async (companyid: string, startDate: string, endDate: string) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_generate_report');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('start', startDate);
		options.append('end', endDate);

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	}

	function initialize() {
		// Get the list of clients.
		if (self.initialized) {
			return;
		}

		// Update the states.
		applySnapshot(self, {...self, initialized: true});
		self.sales = [];
		self.salesStartDate = '';
		self.salesEndDate = '';
		self.courses = [];
		self.coursesStartDate = '';
		self.coursesEndDate = '';
	}

	function logout() {
		// Update the states.
		self.sales = [];
		self.salesStartDate = '';
		self.salesEndDate = '';
		self.courses = [];
		self.coursesStartDate = '';
		self.coursesEndDate = '';
	}

	const setClient = flow(function* setClient(clientId: string) {
		// setup
		const client = clients.findByName(clientId);
		if (!client) {
			return;
		}
		applySnapshot(self, {...self, loading: true}); // for protection

		// TEMPORARY
		const departments = client.departments.map(d => {return {id: d.id, name: d.name}});
		const departmentId = "All Departments";

		// summary stats and engagements
		self.census = client.census;
		if (system.rootUrl.includes('haven')) {
			const stats = yield fetchMdlAnalytics(client.code, CURRENT_YEAR, CURRENT_MONTH);
			self.pageNavigation.init([
				{name: 'location', value: 'selected'},
				{name: 'category', value: 'show'},
				{name: 'survey', value: 'selected'}
			]);
			applySnapshot(self, {...self, id: client.id, name: client.name, logo: client.logo,
				departments, departmentId,
				census: client.census,
				locationStats: stats.company_summary,
				courseSummary: stats.course_summary, courseBreakdown: stats.course_breakdown,
				pollSummary: stats.poll_summary, pollBreakdown: stats.poll_breakdown,
				loading: false});
		} else if (system.rootUrl.includes('aaadrivingcourse') || system.rootUrl.includes('washingtonagd')) {
			const stats = yield fetchMdlAnalytics(client.code, CURRENT_YEAR, CURRENT_MONTH);
			self.pageNavigation.init([
				{name: 'sales', value: 'selected'},
				{name: 'students', value: 'selected'},
				{name: 'coupons', value: 'show'},
				{name: 'return', value: 'static'},
				{name: 'orders', value: 'selected'},
				{name: 'courses', value: 'selected'},
				{name: 'exams', value: 'show'},
				{name: 'certificates', value: 'selected'},
				{name: 'validation', value: 'show'},
				{name: 'logins', value: 'show'},
			]);
			applySnapshot(self, {...self, id: client.id, name: client.name, logo: client.logo,
				departments, departmentId,
				census: client.census,
				locationStats: stats.company_summary,
				courseSummary: stats.course_summary, courseBreakdown: stats.course_breakdown,
				pollSummary: stats.poll_summary, pollBreakdown: stats.poll_breakdown,
				loading: false});
		} else {
			const stats = yield fetchBnAnalytics(client.code, CURRENT_YEAR, CURRENT_MONTH);
			self.pageNavigation.init([
				{name: 'engagement', value: 'selected'},
				{name: 'course', value: 'selected'},
				{name: 'survey', value: 'show'},
				{name: 'insights', value: 'show'},
				{name: 'settings', value: 'selected'},
			]);
			applySnapshot(self, {
				...self, id: client.id, name: client.name, logo: client.logo,
				departments, departmentId,
				census: client.census,
				annualStats: stats.company_summary, monthlyStats: stats.company_breakdown,
				careReason: stats.care_reason, careWho: stats.care_who,
				courseSummary: stats.course_summary, courseBreakdown: stats.course_breakdown,
				pollSummary: stats.poll_summary, pollBreakdown: stats.poll_breakdown,
				loading: false
			});
		}
	})

	function setDepartment(departmentId: string) {
		applySnapshot(self, {...self, departmentId});
	}

	function strCamelize(srcString: string) {
		return srcString.replace('_', ' ').replace(/\w+/g, function(w) {
			return w[0].toUpperCase() + w.slice(1).toLowerCase();
		});
	}

	return {
		clearStats,
		fetchCouponAnalytics,
		fetchCoursesAnalytics,
		fetchSalesAnalytics,
		fetchStudentDetails,
		generateReport,
		initialize,
		logout,
		setClient,
		setDepartment,
		strCamelize
	}
}).views(self => ({

	findResourceByCategory(resources: Resource[], item: Resource) {
		let resource = resources.find((r: Resource) => r.activity_category === item.activity_category);
		if (resource) {
			resource.engagements += item.engagements;
		} else {
			const title = self.strCamelize(item.activity_category);
			resources.push({
				activity_id: item.activity_id,
				activity_type: item.activity_type,
				activity_category: item.activity_category,
				activity_name: item.activity_name,
				activity_title: title,
				engagements: item.engagements,
				ave_time: item.ave_time});
		}
	},

	findResourceByTitle(resources: Resource[], item: Resource) {
		let resource = resources.find((r: Resource) => r.activity_title === item.activity_title);
		if (resource) {
			resource.engagements += item.engagements;
		} else {
			resources.push({
				activity_id: item.activity_id,
				activity_type: item.activity_type,
				activity_category: item.activity_category,
				activity_name: item.activity_name,
				activity_title: item.activity_title,
				engagements: item.engagements,
				ave_time: item.ave_time});
		}
	},

	getAnnualTotals() {
		return self.annualStats;
	},

	getCareTotals(group: string, type: string) {
		// setup
		let reasons:KeyValuePair[] =
			[
				{ id: "Crisis", name: 'Crisis', value: 0, color: "#D61C4E"},
				{ id: "Anxiety", name: 'Anxiety', value: 0, color: "#790252"},
				{ id: "Depression", name: 'Depression', value: 0, color: "#526185FF"},
				{ id: "Relationships", name: 'Relationships', value: 0, color: "#5BB318"},
				{ id: "Addiction", name: 'Addictions', value: 0, color: "#3D8361"},
				{ id: "Work Stress", name: 'Work Stress', value: 0, color: "#FF7F3F"},
				{ id: "Grief or Loss", name: 'Grief or Loss', value: 0, color: "#6B7EADFF"},
				{ id: "Referral/Support", name: 'Other', value: 0, color: "#AB9922FF"},
			];
		let whys:KeyValuePair[] =
			[
				{ id: "Employee", name: 'Employee', value: 0, color: "#4D77FF"},
				{ id: "Loved Ones", name: 'Loved Ones', value: 0, color: "#56BBF1"}
			];

		if (group === 'company' && type === 'reason') {
			for (let i = 0; i < reasons.length; i++) {
				const activity_name = reasons[i].id;
				const reason = self.careReason.find(v => v.activity_name === activity_name);
				if (reason) {
					reasons[i].name = reasons[i].name + ' ' + reason.company_percent + '%';
					reasons[i].value = reason.company_total;
				} else {
					reasons[i].name = reasons[i].name + ' 0%';
				}
			}
			return reasons;
		} else if (group === 'company') {
			for (let i = 0; i < whys.length; i++) {
				const activity_name = whys[i].id;
				const why = self.careWho.find(v => v.activity_name === activity_name);
				if (why) {
					whys[i].name = whys[i].name + ' ' + why.company_percent + '%';
					whys[i].value = why.company_total;
				} else {
					whys[i].name = whys[i].name + ' 0%';
				}
			}
			return whys;
		} else if (type === 'reason') {
			for (let i = 0; i < reasons.length; i++) {
				const activity_name = reasons[i].id;
				const reason = self.careReason.find(v => v.activity_name === activity_name);
				if (reason) {
					reasons[i].name = reasons[i].name + ' ' + reason.all_percent + '%';
					reasons[i].value = reason.all_total;
				} else {
					reasons[i].name = reasons[i].name + ' 0%';
				}
			}
			return reasons;
		} else {
			for (let i = 0; i < whys.length; i++) {
				const activity_name = whys[i].id;
				const why = self.careWho.find(v => v.activity_name === activity_name);
				if (why) {
					whys[i].name = whys[i].name + ' ' + why.all_percent + '%';
					whys[i].value = why.all_total;
				} else {
					whys[i].name = whys[i].name + ' 0%';
				}
			}
			return whys;
		}
	},

	getCourseSummary() {
		return self.courseSummary;
	},

	getCourseBreakdown() {
		return self.courseBreakdown;
	},

	getLocationStats() {
		return self.locationStats;
	},

	getMonthlyTotals(activityType: string) {
		const totals = [];
		for (let i = 0; i < self.monthlyStats.length; i++) {
			if (self.monthlyStats[i].activity_type === activityType) {
				totals.push(self.monthlyStats[i]);
			}
		}
		return totals;
	},

	getPollBreakdown(companyId: string) {
		return (companyId && companyId !== 'All Departments') ?
			self.pollBreakdown.filter(p => p.company === companyId) :
			self.pollBreakdown;
	},

	getPollRange() {
		// Determine the month (incomplete values are not shown).
		let startMonth = CURRENT_MONTH;
		let endMonth = 0;
		for (let i = 0; i < self.pollSummary.length; i++) {
			if (self.pollSummary[i].total > 0 && self.pollSummary[i].month < startMonth) {
				startMonth = self.pollSummary[i].month;
			}
			if (self.pollSummary[i].total > 0 && self.pollSummary[i].month > endMonth) {
				endMonth = self.pollSummary[i].month;
			}
		}
		return {
			startYear: CURRENT_YEAR,
			startMonth: startMonth,
			endYear: CURRENT_YEAR,
			endMonth: endMonth ? endMonth : CURRENT_MONTH
		}
	},

	getPollSummary() {
		return self.pollSummary;
	},

}))
export type Client = Instance<typeof ClientInfo>;

export const defaultClient = ClientInfo.create({
	id: 0,
	name: "Blunovus", // Haven, AAA
	departments: [],
	departmentId: "",
	pageNavigation: {
		// general
		category: '',
		coupons: '',
		course: '',
		engagement: '',
		insights: '',
		location: '',
		survey: '',
		sales: '',
		settings: '',
		students: '',
		// student
		return: '',
		orders: '',
		courses: '',
		exams: '',
		certificates: '',
		validation: '',
		logins: ''
	},
	// stats
	census: 0,
	annualStats: [],
	monthlyStats: [],
	careReason: [],
	careWho: [],
	courseSummary: [],
	courseBreakdown: [],
	pollSummary: [],
	pollBreakdown: [],
	url: packageJson.globals.ROOT_URL,
	logo: packageJson.globals.ROOT_LOGO,
	// status
	initialized: false,
	loading: false
});

/**
 * ClientsInfo - This structure is used for mobx storage efficiency.
 */

export const ClientsInfo = types.model("ClientsInfo", {
	// data
	clients: types.array(CompanyInfo),
	// status
	initialized: types.boolean,
	loading: types.boolean
}).actions(self => {

	function addClient() {

	}

	const fetchClientInfo = flow(function* fetchClientInfo(clientId: number) {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_company');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', clientId.toString());

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	})

	const fetchClients = flow(function* fetchClients() {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_get_companies');
		options.append('moodlewsrestformat', 'json');
		options.append('rootid', system.rootId);

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	})

	const initialize = flow(function* initialize() {
		// Get the list of clients.
		if (self.initialized) {
			return;
		}
		const clients = yield fetchClients();

		// Update the states.
		applySnapshot(self, {...self, clients, initialized: true});
	})

	const updateClientInfo = flow(function* updateClientInfo(clientId: number, fields: NameValuePair[]) {
		// Make sure we have something to save.
		if (!fields.length) {
			return false;
		}

		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_set_company_info');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', clientId.toString());
		options.append('fields', JSON.stringify(fields));

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		return !(!results || results.status !== 200);
	})

	return {
		addClient,
		fetchClientInfo,
		initialize,
		updateClientInfo
	}
}).views(self => ({

	findById(clientId: number) {
		return self.clients.find(client => clientId === client.id);
	},

	findByName(clientId: string) {
		return self.clients.find(client => clientId === client.code || clientId === client.shortname || clientId === client.name);
	},

	mapChildOptions(parentId: number) {
		let children: any[] = [];
		self.clients.forEach(item => {
			if (item.parentid === parentId) {
				children.push(<option value={item.code}>{item.name}</option>);
			}
		});
		return children;
	},

	mapParentItems() {
		return self.clients.map(item => {
			const parent = item.parentid ? ' - ' : '';
			return (<Dropdown.Item id={item.code} key={item.code} eventKey={item.code}>
				<div>{`${parent} ${item.name}`}</div>
				</Dropdown.Item>);
		});
	},

	mapParentOptions() {
		return self.clients.map(item => {
			return <option value={item.code}>{item.name}</option>
		});
	}

}))

export const clients = ClientsInfo.create({
	initialized: false,
	loading: false,
	clients: []
})

/**
 * UserInfo - This structure is used for mobx storage efficiency.
 */

export const UserInfo = types.model("User", {
	id: types.maybeNull(types.string),
	mdl_id: types.number,
	email: types.maybeNull(types.string),
	clientId: types.maybeNull(types.string),
	role: types.enumeration([ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_DEPARTMENT_MANAGER, ROLE_USER]),
	view: types.enumeration([ROLE_ADMIN, ROLE_COMPANY_MANAGER, ROLE_DEPARTMENT_MANAGER, ROLE_USER]),
	errorLevel: types.maybeNull(types.string),
	errorMessage: types.maybeNull(types.string),
}).volatile(_ => ({
	studentId: 0,
	courseId: 0,
	orderId: 0
})).actions(self => {

	/**
	 * Authenticate the user based on their email/password (or corporate login).
	 */
	const authenticate = flow(function* authenticate(email: string, password: string) {
		// admin user patterns
		const manager = ['@lecticon.com', '@blunovus.com'];
		const admin = ['richard@lecticon.com', 'richard@blunovus.com', 'joel@blunovus.com', 'kyle.moon@lecticon.com', 'ted@lecticon.com', 'ted@blunovus.com'];

		// See if we authenticate with MDL or firebase
		let valid = yield mdlAuthenticate(email, password, manager, admin);
		if (!valid) {
			valid = yield fbAuthenticate(email, password, manager, admin);
		}
		if (!valid) {
			const errorMessage = "Invalid email or password";
			applySnapshot(self, {id: null, mdl_id: 0, email: null, clientId: null,
				role: ROLE_USER, view: ROLE_USER,
				errorLevel: "danger", errorMessage});
		}

		// Return the results.
		return !!self.id;
	})

	/**
	 * Authenticate through the firebase system.
	 */
	const fbAuthenticate = flow(function* fbAuthenticate(email: string, password: string, manager: string[], admin: string[]) {

		// Call firebase for validation.
		const success = yield signInWithEmailAndPassword(fbAuth, email, password)
			.then((userCredential) => {
				const user = userCredential.user;
				let role = 'user';
				if (!!admin.find(a => email.includes(a))) {
					role = 'admin';
				} else if (!!manager.find(a => email.includes(a))) {
					role = 'companymanager';
				}
				applySnapshot(self, {...self, id: user.uid, email: user.email, errorLevel: null, errorMessage: null, role});
				return true;
			})
			.catch((err) => {
				// Catch the error.
				return false;
			});

		if (self.id) {
			// success
			const docId = documentId();
			const dbQuery = query(collection(fbDB, 'users'), where(docId, "==", self.id));
			const dbSnapshot = yield getDocs(dbQuery);
			const data = dbSnapshot.docs[0].data();
			applySnapshot(self, {...self, clientId: data.clientId});
		}

		// Get the user (default client) information.
		if (self.role !== 'user') {
			applySnapshot(self, {...self, clientId: system.rootId});
		}

		return success;
	})

	const forgotPassword = flow(function* forgotPassword(email: string) {
		yield sendPasswordResetEmail(fbAuth, email)
			.then((userCredential) => {
				applySnapshot(self, {...self, errorLevel: "warning", errorMessage: "Please check your email."});
			})
			.catch((err) => {
				const errorMessage = `"${email}" is not in our records. Please try again.`;
				applySnapshot(self, {...self, errorLevel: "warning", errorMessage});
			});
	})

	const generateCertificate = async (userid: number, courseid: number) => {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_generate_certificate');
		options.append('moodlewsrestformat', 'json');
		options.append('userid', userid.toString());
		options.append('courseid', courseid.toString());
		options.append('responseType', 'blob');

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	}

	function logout() {
		applySnapshot(self, defaultUser)
	}

	/**
	 * Authenticate through the MDL system.
	 */
	const mdlAuthenticate = flow(function* fbAuthenticate(email: string, password: string, manager: string[], admin: string[]) {
		// setup
		const options = new FormData();
		options.append('wstoken', system.apiKey);
		options.append('wsfunction', 'mdl_authenticate');
		options.append('moodlewsrestformat', 'json');
		options.append('source', 'desktop');
		options.append('companyid', system.rootId);
		options.append('departmentid', "");
		options.append('deviceid', "");
		options.append('username', email);
		options.append('password', password);
		options.append('metadata', "");

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);
		if (results && results.status === 200 && results.data.id) {
			const user = results.data;
			if (!!admin.find(a => email.includes(a))) {
				user.role = user.view = ROLE_ADMIN;
			} else if (!!manager.find(a => email.includes(a))) {
				user.role = user.view = ROLE_COMPANY_MANAGER;
			}

			applySnapshot(self, {...self,
				id: user.username, mdl_id: user.id, clientId: user.companyId ? user.companyId: system.rootId,
				email: user.email, role: user.role,
				errorLevel: null, errorMessage: null});
			return true;
		} else if (!!admin.find(a => email.includes(a))) {
			applySnapshot(self, {...self,
				id: email, clientId: system.rootId, email: email,
				role: ROLE_ADMIN, view: ROLE_ADMIN,
				errorLevel: null, errorMessage: null});
			return true;
		} else if (!!manager.find(a => email.includes(a))) {
			applySnapshot(self, {...self,
				id: email, clientId: system.rootId, email: email,
				role: ROLE_COMPANY_MANAGER, view: ROLE_COMPANY_MANAGER,
				errorLevel: null, errorMessage: null});
			return true;
		}

		return false;
	})

	function setStudentInfo(studentId: number, orderId: number, courseId: number) {
		self.studentId = studentId;
		self.orderId = orderId;
		self.courseId = courseId;
	}

	function setView(view: string) {
		if (view !== self.view) {
			applySnapshot(self, {...self, view});
		}
		return view;
	}

	return {
		authenticate,
		forgotPassword,
		generateCertificate,
		logout,
		setStudentInfo,
		setView
	}

}).views(self => ({

	isAdmin() {
		return (self.role === ROLE_ADMIN);
	},

	isCompanyManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_COMPANY_MANAGER);
	},

	isDepartmentManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_DEPARTMENT_MANAGER);
	},

	isManager() {
		return (self.role === ROLE_ADMIN || self.role === ROLE_COMPANY_MANAGER || self.role === ROLE_DEPARTMENT_MANAGER);
	},

}));

export type User = Instance<typeof UserInfo>;
export const defaultUser = UserInfo.create({
	id: null,
	mdl_id: 0,
	email: null,
	clientId: null,
	role: ROLE_USER,
	errorLevel: null,
	errorMessage: null,
	view: ROLE_USER,
});

interface SearchModel {
	first_name: string,
	last_name: string,
	email: string,
	phone: string,
	postcode: string,
	order_id: number,
	course_id: number,
	course_title: string,
	birth: string,
	license: string,
	ssnum: string,
}
export const SearchFilterInfo = types.model("SearchFilterInfo", {
	first_name: types.string,
	last_name: types.string,
	email: types.string,
	phone: types.string,
	postcode: types.string,
	order_id: types.number,
	course_id: types.number,
	course_title: types.string,
	birth: types.string,
	license: types.string,
	ssnum: types.string,
	filter: types.string,
}).actions(self => {

	function clearFilters() {
		self.first_name = '';
		self.last_name = '';
		self.email = '';
		self.phone = '';
		self.postcode = '';
		self.order_id = 0;
		self.course_id = 0;
		self.course_title = '';
		self.birth = '';
		self.license = '';
		self.ssnum = '';
		self.filter = '';

		applySnapshot(self, {...self});
	}

	function setFilters(search: SearchModel) {
		let filter = '';
		self.first_name = search.first_name;
		if (search.first_name) {
			filter += search.first_name;
		}
		self.last_name = search.last_name;
		if (search.last_name) {
			filter += search.last_name;
		}
		self.email = search.email;
		if (search.email) {
			filter += search.email;
		}
		self.phone = search.phone;
		if (search.phone) {
			filter += search.phone;
		}
		self.postcode = search.postcode;
		if (search.postcode) {
			filter += search.postcode;
		}
		self.order_id = search.order_id;
		if (search.order_id) {
			filter += search.order_id;
		}
		self.course_id = search.course_id;
		if (search.course_id) {
			filter += search.course_id;
		}
		self.course_title = search.course_title;
		if (search.course_title) {
			filter += search.course_title;
		}
		self.birth = search.birth;
		if (search.birth) {
			filter += search.birth;
		}
		self.license = search.license;
		if (search.license) {
			filter += search.license;
		}
		self.ssnum = search.ssnum;
		if (search.ssnum) {
			filter += search.ssnum;
		}
		self.filter = filter;

		applySnapshot(self, {...self});
	}

	return {
		clearFilters,
		setFilters
	}
}).views(self => ({

	getFilters() {
		const filters = {
			first_name: self.first_name,
			last_name: self.last_name,
			email: self.email,
			phone: self.phone,
			order_id: self.order_id,
			course_id: self.course_id,
			course_title: self.course_title,
			birth: self.birth,
			license: self.license,
			ssnum: self.ssnum
		}
		return filters;
	},

}));

export type Filters = Instance<typeof SearchFilterInfo>;
export const searchFilter = SearchFilterInfo.create({
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	postcode: '',
	order_id: 0,
	course_id: 0,
	course_title: '',
	birth: '',
	license: '',
	ssnum: '',
	filter: ''
});
