// react
import React from "react";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";
import {AiOutlineBarChart} from "react-icons/ai";

// local
import {Client, system, System, User} from "../../types";
import {AnalyticsForm} from "../../forms";
import {AnnualEngagements} from "./AnnualEngagements"
import {CareUtilization} from "./CareUtilization"
import {SummaryStats} from "./SummaryStats";

/**
 * CourseSection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction,
	system: System
}
interface SectionState {
	statId: string,
	statName: string,
	chartId: string,
	chartName: string,
}

export class EngagementSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);

		this.state = {
			statId: '',
			statName: '',
			chartId: 'utilization',
			chartName: 'Care Center Utilization',
		}

		// Register the callbacks.
		this.onCancelAnalytics = this.onCancelAnalytics.bind(this);
		this.onEditAnalytics = this.onEditAnalytics.bind(this);
		this.onNavigateEngagements = this.onNavigateEngagements.bind(this);
		this.onSelectChart = this.onSelectChart.bind(this);
		this.onSubmitAnalytics = this.onSubmitAnalytics.bind(this);
		this.onGeneratePDF = this.onGeneratePDF.bind(this);
	}

	onCancelAnalytics() {
		this.setState({statId: "", statName: ""});
	}

	onEditAnalytics(chartId: string, chartName: string) {
		this.setState({statId: chartId, statName: chartName});
	}

	onNavigateEngagements() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/engagements");
	}

	onSelectChart(chartId: string, chartName: string) {
		this.setState({chartId, chartName});
	}

	onSubmitAnalytics() {
		this.setState({statId: "", statName: ""});
	}

	onGeneratePDF = async () => {
		const html = document.getElementById('AnnualStats');
		if (html) {
			await system.generatePDF(html.innerHTML);
		}
	}

	render() {
		// setup
		const {client, user, system} = this.props;
		const {chartId, chartName, statId, statName} = this.state;
		const annualStats = client.getAnnualTotals()
		const monthlyStats = client.getMonthlyTotals(chartId);
		const ctlMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<AiOutlineBarChart className="section-icon" size={28}/>
					Engagement Summary
				</div>
				{user.isAdmin() && <div className="navigate-main">
					<Button variant="forgot" size="sm" onClick={this.onNavigateEngagements}>
						Engagement Details
					</Button>
				</div>}

				<div className="row">
					<div className="column">
						<SummaryStats
							user={user}
							summary={annualStats}
							chartId={chartId}
							chartName={chartName}
							onEditAnalytics={this.onEditAnalytics}
							onSelectChart={this.onSelectChart}
						/>
					</div>
					<div className="column">
						{(chartId === 'utilization') ?
							<CareUtilization
								chartName={chartName}
								summary={monthlyStats}
								ctlMonths={ctlMonths}
							/> :
							<AnnualEngagements
								chartId={chartId}
								chartName={chartName}
								summary={monthlyStats}
								ctlMonths={ctlMonths}
							/>
						}
					</div>
				</div>

				{!!statId && <AnalyticsForm
					system={system}
					statId={statId}
					statName={statName}
					client={client}
					cancel={this.onCancelAnalytics}
					submit={this.onSubmitAnalytics}
				/>}

				<hr/>
				{/*<div onClick={this.onGeneratePDF}>PDF</div>*/}
			</div>
		)
	}
}

