// react
import React from "react";
import {TbSchool} from "react-icons/tb";

// local
import {Client} from "../../types";
import {CourseCategories} from "./CourseCategories";
import {CourseViews} from "./CourseViews";

/**
 * CourseSection
 */

interface SectionProps {
	client: Client
}


export const CourseSection = (props: SectionProps) => {

	// setup
	const {client} = props;
	const categories = client.getCourseSummary();
	const activities = client.getCourseBreakdown();

	// render
	return (
		<div className='section'>
			<div className='section-header'>
				<TbSchool className="section-icon" size={28}/>
				Courses Information
			</div>

			<div className="row">
				<div className="column">
					<CourseCategories categories={categories}/>
				</div>
				<div className="column">
					<CourseViews activities={activities}/>
				</div>
			</div>

			<hr/>
		</div>
	)
}

