// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {ActivitySummary} from "../../types";

/**
 * Course Views
 */

interface ViewsProps {
	activities: ActivitySummary[],
}
interface ViewsState {
}

export class CourseViews extends React.Component<ViewsProps, ViewsState> {

	render() {
		// setup
		let total = 0;
		const {activities} = this.props;

		// header
		let header = [
			<tr key='course-view-header'>
				<th className="description">Title</th>
				<th className="value">views</th>
			</tr>
		];

		// body
		let category = null;
		let body = [];
		for (let i = 0; i < activities.length; i++) {
			// setup
			const item = activities[i];
			const key = 'course-view-'+i;
			total += item.total;

			// Add the item.
			if (item.activity_category !== category) {
				// setup
				category = item.activity_category;
				const key = 'course-view-category-'+i;

				// render
				body.push(<tr key={key}>
					<td className="grouping" colSpan={2}>{category}</td>
				</tr>);
			}
			body.push(<tr key={key}>
				<td className="description">
					{item.activity_title}
				</td>
				<td className="value">{item.total}</td>
			</tr>);
		}
		if (!activities.length) {
			body.push(<tr key={'course-view-empty'}>
				<td className="description warning" colSpan={2}>No Activity</td>
			</tr>);
		}

		// footer
		const footer = [
			<tr key='course-view-footer'>
				<td className="total">{total.toLocaleString()}</td><td className="value">{total}</td>
			</tr>
		];

		// render
		return (
			<div id="CourseViews" key="CourseViews">
				<h4 className="title">Course Views</h4>
				<Table id="CourseViews-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
					<tfoot>{footer}</tfoot>
				</Table>
			</div>
		);
	}

}

