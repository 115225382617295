// react
import React from "react";
import {FiUsers} from "react-icons/fi"

// local
import {Client, CourseInfo, Filters, User} from "../../types";
import {StudentsSummary} from "./StudentsSummary"
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router-dom";

/**
 * UserSection
 */

interface SectionProps {
	user: User,
	client: Client
	navigate: NavigateFunction,
	filters: Filters,
	startDate: string,
	endDate: string,
	onToggleSearchForm: (formName: string | null) => void
}
interface SectionState {
	loaded: boolean,
	clientName: string,
	fCourses: CourseInfo[],
	courseId: number,
	filter: string
}

export class StudentsSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		this.state = {
			loaded: false,
			clientName: props.client.name,
			fCourses: [],
			courseId: 0,
			filter: ''
		};

		// Register the callbacks.
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onSelectItem = this.onSelectItem.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, filters, startDate, endDate} = this.props;

		const data = await client.fetchCoursesAnalytics(client.name, startDate, endDate);
		this.setState({loaded: true, filter: filters.filter,
			fCourses: this.filterCourses(data)
		});
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client, filters, startDate, endDate} = this.props;
		const {clientName} = this.state;

		if (pProps.client.name !== clientName || pProps.startDate !== startDate || pProps.endDate !== endDate) {
			const data = await client.fetchCoursesAnalytics(client.name, startDate, endDate);
			this.setState({loaded: true, clientName: client.name,
				fCourses: this.filterCourses(data), filter: filters.filter
			});
		} else if (filters.filter !== pState.filter) {
			const data = await client.fetchCoursesAnalytics(client.name, startDate, endDate);
			this.setState({fCourses: this.filterCourses(data), filter: filters.filter});
		}
	}

	filterCourses(courses: CourseInfo[]) {
		// setup
		const {filters} = this.props;

		// Filter according to the fields.
		if (filters.last_name) {
			const filter = filters.last_name.toLowerCase();
			courses = courses.filter(item => item.user_last_name.toLowerCase().includes(filter));
		}
		if (filters.first_name) {
			const filter = filters.first_name.toLowerCase();
			courses = courses.filter(item => item.user_first_name.toLowerCase().includes(filter));
		}
		if (filters.email) {
			const filter = filters.email.toLowerCase();
			courses = courses.filter(item => item.email.toLowerCase().includes(filter));
		}
		if (filters.phone) {
			const filter = filters.phone;
			courses = courses.filter(item => item.phone.includes(filter));
		}

		if (filters.course_title) {
			const filter = filters.course_title.toLowerCase();
			courses = courses.filter(item => item.course_title.toLowerCase().includes(filter));
		}

		// Return the results.
		courses = courses.sort((a, b) => {
			if (a.company_id === b.company_id) {
				return a.id > b.id ? 1 : -1;
			}
			return a.company_id > b.company_id ? 1 : -1;
		});
		return courses;
	}

	onGenerateReport = async () => {
		// setup
		// setup
		const {client, startDate, endDate} = this.props;
		const csvData = await client.generateReport(client.name, startDate, endDate);

		const type = 'text/csv';
		const blob = new Blob([csvData], { type: type });
		const dataURI = `data: ${type};charset=utf-8,` + csvData;

		const URL = window.URL || window.webkitURL;
		const filename = `${client.name}-${startDate}-${endDate}.csv`;
		const href = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

		let link = document.createElement('a');
		link.download = filename;
		link.href = href;
		link.target = '_blank';
		link.click();
	}

	onSelectItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {user} = this.props;

		user.setStudentInfo(studentId, orderId, courseId);
		this.setState({courseId: this.state.courseId !== courseId ? courseId : 0});
	}

	render() {
		// setup
		const {user, client, navigate, onToggleSearchForm} = this.props;
		const {loaded, fCourses, courseId} = this.state;
		const search = 'Course Search';

		// render
		if (!loaded) {
			return<></>
		}
		return (
			<div>
				<div className='section'>
					<div className='section-header'>
						<FiUsers className="section-icon" size={28}/>
						Student Progress
						<Button className='navigate-search' variant="forgot" size="sm" onClick={() => onToggleSearchForm(search)}>
							{search}
						</Button>
						<Button className='navigate-report' variant="forgot" size="sm" onClick={this.onGenerateReport}>
							Generate Report
						</Button>
					</div>

					<div className="row">
						<StudentsSummary
							user={user}
							client={client}
							navigate={navigate}
							courses={fCourses}
							courseId={courseId}
							onSelectItem={this.onSelectItem}
						/>
					</div>
					<hr/>

				</div>
			</div>
		)
	}

}

