// react
import React from "react";
import {inject, observer} from "mobx-react";
import { Table} from "react-bootstrap";
import {AiOutlineLogin} from "react-icons/ai";
import {BiPurchaseTag} from "react-icons/bi";
import {TbSchool} from "react-icons/tb";
import {BsListTask, BsPersonCheck} from "react-icons/bs";
import {FaCertificate} from "react-icons/fa"

// local
import {
	AppMenu,
	AppSidebar,
	AppSidebarIcon,
	Footer,
	Header,
} from "../components";
import {
	fieldIdToLabel,
	formatPhoneNumber,
	PageNav,
	PageProps,
	timestampToDuration,
	timestampToStrDate,
	withNavigation,
	User
} from "../types";
import moment from "moment/moment";

/**
 * Support Data
 */

interface AffiliateDetails {
	id: number,
	name: string,
	address: string,
	city: string,
	state: string,
	postcode: string,
}
interface StudentDetails {
	id: number,
	username: string
	first_name: string
	last_name: string,
	email: string,
	phone: string,
	address_1: string,
	address_2: string,
	city: string,
	state: string,
	postcode: string,
	suspended: string
	last_login: string
}
interface OrderDetails {
	id: number,
	title: string
	purchased: number
	qty: number
	subtotal: number
	discount: number
	total: number
}
interface ModuleDetails {
	id: number,
	type: string,
	index: number,
	sort: number,
	title: string,
	completed: number,
	time: number,
	score: number
}
interface CourseDetails {
	id: number,
	course_id: number,
	title: string,
	modules: ModuleDetails[],
	enrolled: number,
	started: number,
	mod_progress: number,
	mod_completed: number,
	mod_total: number,
	completed: number,
	score: number,
	time: number
}
interface ResponseDetails {
	question: string,
	answer: string,
	response: string,
	score: number
	timestamp: number
}
interface CertificateDetails {
	id: number,
	title: string,
	url: string,
	timestamp: number
}
interface ExamDetails {
	title: string,
	attempt: number,
	started: number,
	state: string,
	completed: number,
	grade: number,
	grade_min: number,
	grade_max: number,
	grade_pass: number,
	duration: string
	feedback: string
	responses: ResponseDetails[]
}
interface LoginDetails {
	id: number,
	timestamp: number,
	origin: string,
	ipaddress: string
}
interface ValidationDetails {
	id: number,
	question: string,
	type: string,
	response: string
}
interface ItemDetails {
	title: string,
	affiliate: AffiliateDetails,
	student: StudentDetails,
	orders: OrderDetails[],
	courses: CourseDetails[],
	certificates: CertificateDetails[],
	exams: ExamDetails[],
	validation: ValidationDetails[],
	logins: LoginDetails[]
}

/**
 * StudentPage
 */

interface StudentProps extends PageProps {
}
interface StudentState {
	loaded: boolean,
	item: ItemDetails | null,
}

@inject("user")
@inject("client")
@observer
class StudentPageComponent extends React.Component<StudentProps, StudentState> {

	constructor(props: StudentProps) {
		// setup
		super(props);

		this.state = {
			loaded: false,
			item: null
		}

		// Register the callbacks.
		this.onGoBack = this.onGoBack.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onToggleSection = this.onToggleSection.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, user} = this.props;

		let item = await client.fetchStudentDetails(user.studentId, user.orderId, user.courseId);
		this.setState({item, loaded: true});
	}

	getModuleLogo(title: string, type: string) {
		let src;
		if (type === 'section') {
			return null;
		} else if (type === 'iomadcertificate') {
			src = '/cert.svg';
		} else if (type === 'scorm') {
			src = '/scorm.svg';
		} else if (title.includes('Exam')) {
			src = '/quiz.svg';
		} else {
			src = '/lesson.svg';
		}
		return <img
			className="module-logo"
			src={src}
			alt="module-logo"
		/>
	}

	onGoBack() {
		// setup
		const {navigate} = this.props;
		navigate('/reports');
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onToggleSection(section: string) {
		// setup
		const {client, navigate} = this.props;
		const sort = client.pageNavigation;

		if (section === 'return') {
			navigate('/reports');
		} else {
			sort.toggle(section);
		}
	}

	render() {
		// setup
		const {client, user} = this.props;
		const {loaded, item} = this.state;
		if (!loaded || !item) {
			return <></>
		}

		// render
		const show = client.pageNavigation;
		const isManager = user.isManager();
		const questions = !!item.validation.length;
		return (
			<div className="page">
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>

				<div id="StudentPage-body" className="body">
					{ this.renderSidebar(user, client.pageNavigation, questions) }
					<div className="scroll-y-full">
						<div className="row">
							<div className="column">
								{this.renderStudentInfo(item.student)}
							</div>
							<div className="column">
								{this.renderAffiliateInfo(item.affiliate)}
							</div>
							<hr/>
						</div>
						{show.orders === 'selected' && this.renderOrdersInfo(item.orders, user.orderId)}
						{show.courses === 'selected' && this.renderCoursesInfo(item.courses, user.courseId)}
						{show.certificates === 'selected' && this.renderCertificateInfo(item.certificates)}
						{show.exams === 'selected' && this.renderExamsInfo(item.exams, isManager)}
						{show.validation === 'selected' && questions && this.renderValidationInfo(item.validation)}
						{show.logins === 'selected' && this.renderLoginInfo(item.logins)}

					</div>
				</div>

				<Footer/>
			</div>
		);
	}

	renderAffiliateInfo(affiliate: AffiliateDetails) {
		// setup
		const header =
			<tr key='affiliate-header' className='small-font'>
				<th className='left-col'>Affiliate</th>
				<th className='right-col'>Contact</th>
			</tr>;

		const body = [];
		body.push(<tr key='affiliate-row1'>
			<td className='left-col'>{affiliate.name}</td>
			<td className='right-col'>{'Address: ' + affiliate.address}</td>
		</tr>);
		body.push(<tr key='affiliate-row2'>
			<td className='left-col'/>
			<td className='right-col'>{'City: ' + affiliate.city}</td>
		</tr>);
		body.push(<tr key='affiliate-row3'>
			<td className='left-col'/>
			<td className='right-col'>{'State: ' + affiliate.state}</td>
		</tr>);
		body.push(<tr key='affiliate-row4'>
			<td className='left-col'/>
			<td className='right-col'>{'Postcode: ' + affiliate.postcode}</td>
		</tr>);

		// render
		return (
			<Table id="Student-Affiliate-Table" bordered>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
			</Table>
		);
	}

	renderCertificateInfo(certificates: CertificateDetails[]) {
		// setup
		const header =
			<tr key='affiliate-header' className='small-font'>
				<th className='value'>#</th>
				<th className='value'>Date</th>
				<th className='description'>Description</th>
				<th className='value'>Certificate</th>
			</tr>;

		let body = [];
		for (let i = 0; i < certificates.length; i++) {
			// setup
			const certificate = certificates[i];
			const started = timestampToStrDate(certificate.timestamp, false);
			const pdf = <a href={certificate.url} target='_blank' rel="noreferrer">
				<img className='field-icon' src='/pdf.png' alt='student-certificate'></img>
			</a>;

			// render
			body.push(<tr>
				<td className='value'>{certificate.id}</td>
				<td className='date'>{started}</td>
				<td className='description'>{certificate.title}</td>
				<td className={'value'}>{pdf}</td>
			</tr>);
		}
		if (!certificates.length) {
			body.push(<tr key='certificates-none'>
				<td colSpan={4}>No certificates</td>
			</tr>);
		}

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<FaCertificate className="section-icon" size={28}/>
					Certificates
				</div>

				<div className="row">
					<div id="StudentExams">
						<Table id="Student-Certificate-Table" bordered responsive>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
						</Table>
					</div>
				</div>
				<hr/>
			</div>
		);
	}

	renderCoursesInfo(courses: CourseDetails[], courseId: number) {
		// setup
		let header =
			<tr key='courses-header' className='small-font'>
				<th className='value'>ID</th>
				<th className='description'>title</th>
				<th className='value'>enrolled</th>
				<th className='value'>started</th>
				<th className='percent'>progress</th>
				<th className='value'>finished</th>
				<th className='percent'>score</th>
				<th className='value'>HH:MM</th>
			</tr>;

		let enrolled = 0;
		let started = 0;
		let completed = 0;
		let time = 0;
		let body = [];
		for (let i = 0; i < courses.length; i++) {
			// setup
			const course = courses[i];
			const strEnrolled = timestampToStrDate(course.enrolled, false);
			const strStarted = timestampToStrDate(course.started, false);
			const strCompleted = timestampToStrDate(course.completed, false);
			const selected = (course.id === courseId) ? 'course selected' : 'course';
			const progress = course.started ? course.mod_progress+'%' : '';
			const score = course.score ? course.score + '%' : '';

			if (course.enrolled) {
				enrolled++;
			}
			if (course.started) {
				started++;
			}
			if (course.completed) {
				completed++;
			}

			const modules = this.renderModulesInfo(course.modules);
			const timestamp = timestampToDuration(modules.time);
			time += modules.time;

			// render
			const key = 's-course-'+course.course_id;
			body.push(<tr className={selected} id={key} key={key}>
				<td className='value'>{course.id}</td>
				<td className='description'>{course.title}</td>
				<td className='value'>{strEnrolled}</td>
				<td className='value'>{strStarted}</td>
				<td className='percent'>{progress}</td>
				<td className='value'>{strCompleted}</td>
				<td className='value'>{score}</td>
				<td className='value'>{timestamp}</td>
			</tr>);
			for (let j = 0; j < modules.body.length; j++) {
				body.push(modules.body[j]);
			}
		}
		if (!body.length) {
			body.push(<tr key='s-course-none'>
				<td colSpan={8}>No courses</td>
			</tr>);
		}

		const timestamp = timestampToDuration(time);
		const footer = body.length ? <tfoot>
			<tr key='s-course-footer' >
				<td colSpan={2}></td>
				<td className='value'>{enrolled}</td>
				<td className='value'>{started}</td>
				<td/>
				<td className='value'>{completed}</td>
				<td/>
				<td className='value'>{timestamp}</td>
			</tr>
		</tfoot> :
		null;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<TbSchool className="section-icon" size={28}/>
					Enrolled Courses
				</div>

				<div className="row">
					<div id="StudentCourses">
						<Table id="Student-Courses-Table" bordered responsive>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
							{footer}
						</Table>
					</div>
				</div>
				<hr/>
			</div>
		);
	}

	renderExamInfo(exam: ExamDetails, isManager: boolean) {
		// summary
		const started = timestampToStrDate(exam.started, true);
		const completed = timestampToStrDate(exam.completed, true);

		const score = exam.grade + ' out of ' + exam.grade_max + ' (' + Math.ceil(exam.grade * 100 / exam.grade_max) + '%)';
		const success = (exam.grade >= exam.grade_pass)? 'text-success' : 'text-danger';

		const summary = <div className='table-summary'>
			<div className='left-col'>
				<div className='title'>{exam.title}</div>
				<div><strong>Attempt: </strong>{exam.attempt}</div>
				<div><strong>Started: </strong>{started}</div>
				<div><strong>State: </strong>{exam.state}</div>
				<div><strong>Completed: </strong>{completed}</div>
				<div><strong>Time taken: </strong>{exam.duration}</div>
			</div>
			<div className='right-col'>
				<div className={success}><strong>Score: </strong>{score}</div>
				<br/>
				<div className={success}><strong>Feedback: </strong>{exam.feedback}</div>
			</div>
		</div>;

		// body
		let body = [];
		for (let i = 0; i < exam.responses.length; i++) {
			// setup
			const response = exam.responses[i];
			const question = response.question.split("\n");
			const success = response.score ? 'text-success' : 'text-danger';
			const score = response.score ? "✓" : "✗";
			const timestamp = moment(response.timestamp * 1000).format('h:mm:ss a');
			const answer = response.answer;
			const fmtQuestion = isManager ?
				question.map(item => {
					return item.includes(answer) ? <div className='text-success'>{item}</div> : <div>{item}</div>;
				}) :
				question.map(item => <div>{item}</div>);

			// render
			body.push(<tr key={'s-exam-'+i}>
				<td>{i+1}</td>
				<td className='multiline-description'>{fmtQuestion}</td>
				<td className='time'>{timestamp}</td>
				<td className={'response ' + success}>{response.response}</td>
				<td className={'value ' + success}>{score}</td>
			</tr>);
		}

		// render
		return (
			<div className='row'>
				{summary}
				<Table id="Student-Exams-Table" bordered striped responsive>
					<thead className='summary'>
						<tr className='small-font'>
							<th>#</th>
							{isManager ?
								<th className='description'>Question (Highlight shows correct response)</th> :
								<th className='description'>Question</th>
							}
							<th className='time'>Time</th>
							<th className='response'>Response</th>
							<th className='value'>Credit</th>
						</tr>
					</thead>
					<tbody>{body}</tbody>
				</Table>
				<hr/>
			</div>
		);
	}

	renderExamsInfo(exams: ExamDetails[], isManager: boolean) {
		// setup
		const details = exams.length ?
			exams.map(exam => this.renderExamInfo(exam, isManager)) :
			<div className='row'>
					<Table id="Student-Exams-Table" bordered responsive>
						<thead className='summary'>
						<tr className='small-font'>
							<th>#</th>
							<th className='description'>Question</th>
							<th className='time'>Time</th>
							<th className='response'>Response</th>
							<th className='value'>Credit</th>
						</tr>
						</thead>
						<tbody>
							<tr key='exams-none'><td colSpan={5}>No completed exams</td></tr>
						</tbody>
					</Table>
					<hr/>
				</div>
		;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<BsListTask className="section-icon" size={28}/>
					Student Exams
				</div>

				{details}

			</div>
		);
	}

	renderLoginInfo(logins: LoginDetails[]) {
		// setup
		let header =
			<tr key='courses-header' className='small-font'>
				<th className='timestamp'>time</th>
				<th className='value'>origin</th>
				<th className='description'>ipaddress</th>
			</tr>;

		let body = [];
		for (let i = 0; i < logins.length; i++) {
			// setup
			const login = logins[i];
			const timestamp = timestampToStrDate(login.timestamp, true);

			// render
			body.push(<tr key={'s-login'+i}>
				<td className='timestamp'>{timestamp}</td>
				<td className='value'>{login.origin}</td>
				<td className='description'>{login.ipaddress}</td>
			</tr>);
		}
		if (!logins.length) {
			body.push(<tr key='s-login-none'>
				<td colSpan={3}>No recorded logins</td>
			</tr>);
		}

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<AiOutlineLogin className="section-icon" size={28}/>
					Student Logins
				</div>

				<div className="row">
					<div id="StudentLogins">
						<Table id="Student-Logins-Table" bordered responsive>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
						</Table>
					</div>
				</div>
				<hr/>
			</div>
		);
	}

	renderModulesInfo(modules: ModuleDetails[]) {
		// setup
		let time = 0;
		modules = modules.sort((a, b) => a.sort > b.sort ? 1 : -1);

		// render
		let body = [];
		for (let i = 0; i < modules.length; i++) {
			// setup
			const module = modules[i];
			let completed = '';
			let timestamp = '';
			let className;
			if (module.type === 'section') {
				className = 'module-group';
			} else if (module.type === 'iomadcertificate') {
				className = 'module';
				completed = '';
				timestamp = '';
			} else {
				className = 'module';
				completed = timestampToStrDate(module.completed, false);
				timestamp = timestampToDuration(module.time);
				time += module.time;
			}
			const score = module.completed ? (module.score ? module.score + '%' : '●') : '';
			const img = this.getModuleLogo(module.title, module.type);

			// render
			const key = 's-module-'+module.id;
			body.push(<tr id={key} className={className} key={key}>
				<td></td>
				<td className='description'>{img}{module.index + ' ' + module.title}</td>
				<td className='value'></td>
				<td className='value'></td>
				<td className='percent'></td>
				<td className='value'>{completed}</td>
				<td className='value'>{score}</td>
				<td className='value'>{timestamp}</td>
			</tr>);
		}

		return {
			body,
			time
		};
	}

	renderOrdersInfo(orders: OrderDetails[], orderId: number) {
		// setup
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 0
		});

		// header
		const header =
			<tr key='orders-header' className='small-font'>
				<th className='value'>ID</th>
				<th className='description'>title</th>
				<th className='date'>date</th>
				<th className='value'>qty</th>
				<th className='value'>subtotal</th>
				<th className='value'>discount</th>
				<th className='value'>total</th>
			</tr>;

		// body
		let qty = 0;
		let subtotal = 0;
		let discount = 0;
		let total = 0;
		let body = [];
		for (let i = 0; i < orders.length; i++) {
			// setup
			const order = orders[i];

			const title = (!order.qty && order.discount) ? 'Code: ' + order.title : order.title;
			const purchased = order.qty ? timestampToStrDate(order.purchased, false) : '';
			const selected = (order.id === orderId) ? 'selected' : '';
			qty += order.qty;
			subtotal += order.subtotal;
			discount += order.discount;
			total += order.total;


			// render
			body.push(<tr className={selected} key={'s-order'+order.id}>
				<td className='value'>{order.id}</td>
				<td className='description'>{title}</td>
				<td className='date'>{purchased}</td>
				<td className='value'>{order.qty ? order.qty : ''}</td>
				<td className='value'>{order.subtotal.toLocaleString()}</td>
				<td className='value'>{order.discount.toLocaleString()}</td>
				<td className='value'>{order.total.toLocaleString()}</td>
			</tr>);
		}
		if (!orders.length) {
			body.push(<tr key='s-order-none'>
				<td colSpan={5}>No orders</td>
			</tr>);
		}

		// footer
		const footer = orders.length ? <tfoot>
			<tr key='s-order-footer' >
				<td colSpan={3}></td>
				<td className='value'>{qty}</td>
				<td className='value'>{formatter.format(subtotal)}</td>
				<td className='value'>{formatter.format(discount)}</td>
				<td className='value'>{formatter.format(total)}</td>
			</tr>
			</tfoot> :
			null;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<BiPurchaseTag className="section-icon" size={28}/>
					Order Information
				</div>

				<div className="row">
					<div id="StudentOrders">
						<Table id="Student-Order-Table" bordered responsive>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
							{footer}
						</Table>
					</div>
				</div>
				<hr/>
			</div>
		);
	}

	renderSidebar(user: User, show: PageNav, showValidation: boolean) {
		return (
			<AppSidebar>
				{user.isManager() && <AppSidebarIcon
					section='return'
					text='Return'
					state={show.return}
					onSelect={this.onToggleSection}
				/>}
				<AppSidebarIcon
					section='orders'
					text='Orders'
					state={show.orders}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='courses'
					text='Courses'
					state={show.courses}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='certificates'
					text='Certificates'
					state={show.certificates}
					onSelect={this.onToggleSection}
				/>
				<AppSidebarIcon
					section='exams'
					text='Exams'
					state={show.exams}
					onSelect={this.onToggleSection}
				/>
				{showValidation && <AppSidebarIcon
					section='validation'
					text='Validation'
					state={show.validation}
					onSelect={this.onToggleSection}
				/>}
				<AppSidebarIcon
					section='logins'
					text='Logins'
					state={show.logins}
					onSelect={this.onToggleSection}
				/>
			</AppSidebar>
		);
	}

	renderStudentInfo(student: StudentDetails) {
		// setup
		const header =
			<tr key='student-header' className='small-font'>
				<th className='left-col'>Student Details</th>
				<th className='right-col'>Billing</th>
			</tr>;

		let body = [];
		let user = [
			'id', 'first_name', 'last_name', 'username', 'email', 'phone', 'last_login'
		];
		let billing = [
			'address_1', 'address_2', 'city', 'state', 'postcode', '', ''
		];
		for (let i = 0; i < billing.length; i++) {
			// @ts-ignore
			let uValue = user[i] ? student[user[i]] : '';
			if (user[i] === 'phone') {
				uValue = formatPhoneNumber(uValue);
			} else if (user[i] === 'last_login') {
				uValue = timestampToStrDate(uValue, true);
			}
			const uLabel = user[i] ? fieldIdToLabel(user[i]) : '';
			const uSeparator = user[i] ? ': ' : '';

			// @ts-ignore
			const bValue = billing[i] ? student[billing[i]] : '';
			const bLabel = billing[i] ? fieldIdToLabel(billing[i]) : '';
			const bSeparator = billing[i] ? ': ' : '';

			body.push(<tr key={`student-row-${i}`}>
				<td className='left-col'>{uLabel + uSeparator + uValue}</td>
				<td className='right-col'>{bLabel + bSeparator + bValue}</td>
			</tr>)
		}

		// render
		return (
			<Table id="Student-Student-Table" bordered>
				<thead>{header}</thead>
				<tbody>{body}</tbody>
			</Table>
		);
	}

	renderValidationInfo(questions: ValidationDetails[]) {
		// setup
		const header =
			<tr key='validation-header' className='small-font'>
				<td className='value'>#</td>
				<td className='description'>Question</td>
				<td className='response'>Response</td>
			</tr>;

		let body = [];
		for (let i = 0; i < questions.length; i++) {
			// setup
			const question = questions[i];
			let response;
			if (question.response === '') {
				response = 'no response';
			} else if (question.type === 'checkbox' ) {
				response = !!parseInt(question.response) ? 'yes' : 'no';
			} else {
				response = question.response;
			}

			// render
			body.push(<tr key={'s-validation'+i}>
				<td className='value'>{i}</td>
				<td className='description'>{question.question}</td>
				<td className='response'>{response}</td>
			</tr>);
		}
		if (!questions.length) {
			body.push(<tr key='s-validation-none'>
				<td colSpan={5}>No questions</td>
			</tr>);
		}

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<BsPersonCheck className="section-icon" size={28}/>
					Student Validation Questions
				</div>

				<div className="row">
					<div id="StudentValidation">
						<Table id="Student-Validate-Table" bordered striped>
							<thead>{header}</thead>
							<tbody>{body}</tbody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

export const StudentPage = withNavigation(StudentPageComponent);
