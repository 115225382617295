// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {
	CouponUseInfo,
	firstLastToName, timestampToStrDate, User
} from "../../types";
import {NavigateFunction} from "react-router-dom";

/**
 * Coupons Summary
 */

interface CouponProps {
	user: User,
	navigate: NavigateFunction,
	use: CouponUseInfo[],
}
interface CouponState {
}

export class Coupons extends React.Component<CouponProps, CouponState> {

	constructor(props: CouponProps) {
		super(props);

		this.state = {
		}

		// Register the callbacks.
		this.onViewItem = this.onViewItem.bind(this);
	}

	onViewItem(studentId: number, orderId: number, courseId: number) {
		// setup
		const {navigate, user} = this.props;

		// Navigate to the student page.
		user.setStudentInfo(studentId, orderId, courseId);
		navigate("/student");
	}

	render() {
		// setup
		const {use} = this.props;

		// Construct the list.
		const header = [
			<tr key='sales-company-header'>
				<th className="value">Order</th>
				<th className="value">Date</th>
				<th className="description">Student</th>
				<th className="value">Amount</th>
			</tr>
		];

		const body = [];
		for (let i = 0; i < use.length; i++) {
			// coupon
			const item = use[i];
			const created = timestampToStrDate(item.created, false);
			const name = firstLastToName(item.user_last_name, item.user_first_name);
			const amount = item.coupon_amount.toLocaleString();

			body.push(
				<tr
					key={'coupon-use-'+i}
				>
					<td
						className='link'
						onClick={() => this.onViewItem(item.user_id, item.id, 0)}
					>
						{item.id}
					</td>
					<td className='date'>{created}</td>
					<td className='description'>{name}</td>
					<td className='value'>{amount}</td>
				</tr>
			);
		}

		// render
		return (
			<div id="CouponsSummary">
				<Table id="CouponsSummary-Table" striped bordered responsive>
					<thead>{header}</thead>
					<tbody>{body}</tbody>
				</Table>
			</div>
		)
	}

}
