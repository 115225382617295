// react
import React from "react";
import {
	ResponsiveContainer,
	Tooltip,
	Legend,
	BarChart,
	CartesianGrid,
	Bar,
	YAxis,
	XAxis
} from 'recharts';

// local
import {LocationSummary, TOOLTIP_STYLE} from "../../types";

/**
 * SummaryChart
 */

interface ChartProps {
	data: LocationSummary[]
}
interface ChartState {
}

export class LocationChart extends React.Component<ChartProps, ChartState> {

	tooltip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			return (
				<div className="tip-custom">
					<div className="tip-title">{payload[0].payload.name}</div>
					<div className="tip-this-year">{`${payload[0].value}%`}</div>
				</div>
			);
		}
		return null;
	};

	yAxis = (tickItem: any) => {
		return tickItem.toString()+'%';
	};

	render() {
		// setup
		const {data} = this.props;
		let stats = [];
		for (let i = 0; i < data.length; i++) {
			const value = data[i].users ? Math.ceil((data[i].activity / data[i].users) * 100) : 0;
			stats.push({id: i+1, name: data[i].name, users: data[i].users, activity: data[i].activity, value});
		}

		// render
		// render
		return (
			<div id="AnnualStats">
				<h4 className="title">Participation</h4>
				<ResponsiveContainer width="100%" height={300}>
					<BarChart width={200} height={100} data={stats}>
						<CartesianGrid strokeDasharray="3 3" vertical={false}/>
						<Bar dataKey={'value'} name={"Location"} fill="#4D77FF"/>
						<YAxis
							label={{ value: '% Participation', angle: -90, position: 'insideLeft' }}
							tickFormatter={this.yAxis}
						/>
						<Legend />
						<XAxis
							dataKey="id"
							fontFamily="sans-serif"
							label={{ value: ''}}
							dy='25'
						/>
						<Tooltip content={this.tooltip} wrapperStyle={TOOLTIP_STYLE} cursor={{fill: '#3880ff54'}}/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		);
	}

}
