// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {ActivitySummary, User} from "../../types";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {PopupMessage} from "../../components";

/**
 * SummaryStats
 */

export let summaryStats = [
	{ id: "utilization", name: 'Care Center Utilization', value: 0},
	{ id: "care", name: 'Care Center Engagements', value: 0},
	{ id: "census", name: 'Number of Employees', value: 0},
	{ id: "hope", name: 'Hope & Norm Opt-Ins', value: 0},
	{ id: "training", name: 'Live Training Engagements', value: 0},
	{ id: "download", name: 'App Downloads', value: 0},
	{ id: "app", name: 'App Engagements', value: 0},
	{ id: "course", name: 'Courses Viewed', value: 0},
	{ id: "post", name: 'Posts Viewed', value: 0},
	{ id: "story", name: 'Stories Viewed', value: 0},
]

interface SummaryProps {
	user: User,
	summary: ActivitySummary[],
	chartId: string,
	chartName: string,
	onEditAnalytics: (chartId: string, chartName: string) => void
	onSelectChart: (chartId: string, chartName: string) => void
}
interface SummaryState {
	showInfoMessage: boolean,
	infoTitle: string,
	infoBody: any,
}

export class SummaryStats extends React.Component<SummaryProps, SummaryState> {

	constructor(props: SummaryProps) {
		super(props);

		this.state = {
			showInfoMessage: false,
			infoTitle: "Navigating Engagements",
			infoBody: <div>Get engagement details by clicking the list item. Once selected, the 'Engagements' list will show only that summary item. <br/>Revert back to the global view by re-clicking the selected list item.</div>
		}

		// Register the callbacks.
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onShowPopup = this.onShowPopup.bind(this);
	}

	onDismissPopup() {
		this.setState({showInfoMessage: false});
	}

	onShowPopup() {
		this.setState({showInfoMessage: true});
	}

	render() {
		// setup
		const {summary, chartId, onSelectChart, user} = this.props;
		const {showInfoMessage, infoTitle, infoBody} = this.state;

		// Construct the list.
		const body = summaryStats.map(item => {
			// special handling
			let value, chart = null;
			const stat = summary.find(s => s.activity_type === item.id);
			if (!stat) {
				value = 0;
			} else if (item.id === 'care' && !user.isAdmin()) {
				return null;
			} else if (item.id === 'utilization') {
				value = (Math.ceil(stat.total * 10) / 10) + '%'; // percentage rather than raw number
			} else {
				value = stat.total.toLocaleString('en-US');
			}
			const selected = (item.id === chartId) ? 'selectable selected' : 'selectable';

			// render
			return (
				<tr
					id={item.id}
					key={'summary'+item.id}
					className={selected}
					onClick={() => onSelectChart(item.id, item.name)}
				>
					<td className="description">{item.name}</td>
					<td className="value">{value}</td>
					{chart}
				</tr>);
		});

		// render
		return (
			<div id="SummaryStats">
				<AiOutlineInfoCircle
					className="info-icon"
					size={24}
					onClick={this.onShowPopup}
				/>
				<h4 className="title">12-Month Engagement Summary</h4>
				<Table id="SummaryStats-Table" striped bordered responsive>
					<tbody>{body}</tbody>
				</Table>

				<PopupMessage
					show={!!showInfoMessage}
					title={infoTitle}
					body={infoBody}
					bg="info"
					onClose={this.onDismissPopup}
				/>
			</div>
		);
	}

}

