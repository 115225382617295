// react
import React from "react";
import {TbSchool} from "react-icons/tb";

// local
import {Client, User} from "../../types";
import {CoursesSummary} from "./CoursesSummary";
import {CoursesViews} from "./CoursesViews";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";

/**
 * CategorySection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction
}
interface SectionState {
	activityId: string
}

export class CategorySection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);

		this.state = {
			activityId: ""
		};

		// Register the callbacks.
		this.onNavigateCourses = this.onNavigateCourses.bind(this);
		this.onSelectActivity = this.onSelectActivity.bind(this);
	}

	onNavigateCourses() {
		// Go to the courses page.
		this.props.navigate("/courses");
	}

	onSelectActivity(activityId: string) {
		this.setState({activityId: (this.state.activityId === activityId) ? "" : activityId});
	}

	render() {
		// setup
		const {client, user} = this.props;
		const {activityId} = this.state;
		const categories = client.getCourseSummary();
		const activities = client.getCourseBreakdown();

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<TbSchool className="section-icon" size={28}/>
					Course Information
				</div>

				{user.isCompanyManager() && <div className="navigate-main">
					<Button
						variant="forgot" size="sm"
						onClick={this.onNavigateCourses}>
						Course Details
					</Button>
				</div>}

				<div className="row">
					<div className="column">
						<CoursesSummary
							categories={categories}
							onSelectActivity={this.onSelectActivity}
							activityId={activityId}
						/>
					</div>
					<div className="column">
						<CoursesViews
							activities={activities}
							activityId={activityId}
						/>
					</div>
				</div>

				<hr/>
			</div>
		)
	}

}

