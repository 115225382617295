// react
import React from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {FaEye, FaEyeSlash} from "react-icons/fa";

// local
import {User, Client, System} from "../types";

/**
 * LoginForm
 */

interface FormProps {
	system: System,
	user: User,
	client: Client,
	submit: (action: string | null) => void,
	errorLevel: string | null,
	errorMessage: string | null
}
interface FormState {
	passVisible: boolean
}

export class LoginForm extends React.Component<FormProps, FormState> {

	constructor(props: FormProps) {
		super(props);

		this.state = {
			passVisible: false
		}

		this.onForgotPassword = this.onForgotPassword.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.onTogglePassword = this.onTogglePassword.bind(this);
	}

	onForgotPassword() {
		// Navigate to the forgot-password page.
		this.props.submit('forgotpassword');
	}

	onFormSubmit = async (event: any) => {
		// setup
		const {user, client, submit} = this.props;
		event.preventDefault();
		const fields = event.currentTarget.elements;

		// Update the user information.
		const email = fields.email.value;
		const password = fields.password.value;
		const success = await user.authenticate(email, password);

		// Navigate to login, if successful.
		if (!success) {
			this.props.submit(null);
		} else if (user.clientId) {
			await client.setClient(user.clientId);
			const action = (client.url.includes('blunovus') || user.isDepartmentManager() || user.isCompanyManager()) ? 'reports' : 'student';
			submit(action);
		}
	}

	onTogglePassword() {
		this.setState({passVisible: !this.state.passVisible});
	}

	render() {
		// setup
		const {errorLevel, errorMessage} = this.props;
		const {passVisible} = this.state;

		// render
		return (
			<div className="form-body">
				<div className="form">
					<Form id="LoginForm" className="mt-3" onSubmit={this.onFormSubmit}>

						{ /* email & password */ }
						<Form.Group className="mb-3 form-field" controlId="email">
							<Form.Control type="email" placeholder="Enter email" />
						</Form.Group>
						<Form.Group className="mb-3 password-container form-field" controlId="password">
							{passVisible
								? <FaEyeSlash className="password-eye" onClick={this.onTogglePassword}/>
								: <FaEye className="password-eye" onClick={this.onTogglePassword}/>
							}
							<Form.Control type={passVisible ? "text" : "password"} placeholder="Password" />
						</Form.Group>
						{errorLevel &&
							<Alert key={errorLevel} variant={errorLevel} className="btn-error">
								{errorMessage}
							</Alert>
						}

						<Button variant="primary" className="btn-wide mt-3" type="submit">Login</Button>

					</Form>
				</div>

				<Button variant="forgot" className="btn-wide" size="sm" onClick={this.onForgotPassword}>
					Forgot password
				</Button>
			</div>
		);
	}

}

