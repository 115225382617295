// react
import React from "react";
import {AiOutlineBarChart} from "react-icons/ai";

// local
import {Client} from "../../types";
import {LocationChart} from "./LocationChart";
import {LocationStats} from "./LocationStats";

/**
 * LocationSection
 */

interface SectionProps {
	client: Client
}


export const LocationSection = (props: SectionProps) => {

	// setup
	const {client} = props;
	const summary = client.getLocationStats();

	// render
	return (
		<div className='section'>
			<div className='section-header'>
				<AiOutlineBarChart className="section-icon" size={28}/>
				Engagement Summary
			</div>

			<div className="row">
				<div className="column">
					<LocationStats summary={summary}/>
				</div>
				<div className="column">
					{summary.length > 1 && <LocationChart data={summary}/>}
				</div>
			</div>
			<hr/>

		</div>
	)
}

